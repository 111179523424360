import { Button } from "./Button"
import styles from "./ClearFormFieldButton.module.css"
export const ClearFormFieldButton = ({ targetFields, buttonText, formState, setFormData }) => {

    /* --------------------------------------------------------------------------------------- */
    /* RESET FORM */
    /* --------------------------------------------------------------------------------------- */
    const resetTargetedFields = (fields) => {
        const updatedFields = { ...formState.inputs };

        fields.forEach(field => {
            const currentValue = updatedFields[field].value;

            // Check if the field value is an array, object, or string and reset accordingly
            if (Array.isArray(currentValue)) {
                updatedFields[field] = { value: [], isValid: true };  // Empty array
            } else if (typeof currentValue === 'object' && currentValue !== null) {
                updatedFields[field] = { value: {}, isValid: true };  // Empty object
            } else {
                updatedFields[field] = { value: '', isValid: true };  // Empty string or fallback
            }
        });

        setFormData(updatedFields, formState.isValid);
    };

    return (
        <div className={styles.buttonContainer}>
           <Button
            onClick={() => resetTargetedFields(targetFields)}
            buttonStyleType="clearFormField">
            {buttonText}
        </Button> 
        </div>
        
    )
}

// import { Button } from "./Button";
// import styles from "./ClearFormFieldButton.module.css";

// export const ClearFormFieldButton = ({ targetFields, buttonText, formState, setFormData }) => {

//     /* --------------------------------------------------------------------------------------- */
//     /* RESET FORM */
//     /* --------------------------------------------------------------------------------------- */
//     const resetTargetedFields = (fields) => {
//         const updatedFields = { ...formState.inputs };

//         fields.forEach(field => {
//             const currentValue = updatedFields[field].value;

//             // Check if the field value is an array, object, or string and reset accordingly
//             if (Array.isArray(currentValue)) {
//                 updatedFields[field] = { value: [], isValid: true };  // Empty array
//             } else if (typeof currentValue === 'object' && currentValue !== null) {
//                 updatedFields[field] = { value: {}, isValid: true };  // Empty object
//             } else {
//                 updatedFields[field] = { value: '', isValid: true };  // Empty string or fallback
//             }
//         });

//         setFormData(updatedFields, formState.isValid);
//     };

//     // Check if any target fields have non-empty or non-null values
//     const isButtonDisabled = targetFields.every(field => {
//         const fieldValue = formState.inputs[field]?.value;
//         return fieldValue === '' || fieldValue === null || (Array.isArray(fieldValue) && fieldValue.length === 0);
//     });

  
//     return (
//         <div className={styles.buttonContainer}>
//            <Button
//             onClick={() => resetTargetedFields(targetFields)}
//             buttonStyleType="clearFormField"
//             disabled={isButtonDisabled}  // Disable button if all fields are empty or null
//             >
//             {buttonText}
//         </Button> 
//         </div>
//     );
// }
