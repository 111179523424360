import { useRef } from 'react';
import { PageText } from '../../Text/Text';
import { motion, useInView } from 'framer-motion';
import styles from './GenericScrollHeader.module.css';
import { ScrollButtons } from '../Button/ScrollButtons';

export const GenericScrollHeader = ({
    headerText,
    leftDisabled,
    leftOnClick,
    rightDisabled,
    rightOnClick
}) => {
    const pageTextRef = useRef(null);
    const pageTextInView = useInView(pageTextRef, { once: true, threshold: 0.5 });

    return (
        <div className={styles.scrollBarContainer}>
            {
                headerText ?
                    <div className={styles.headerContainer}>
                        <div className={styles.headerTitleWrapper}>
                        <motion.div
                                ref={pageTextRef}
                                initial={{ y: 20, opacity: 0 }}
                                animate={pageTextInView ? { y: 0, opacity: 1 } : {}}
                                transition={{ duration: 0.5, ease: 'easeOut' }}
                            >
                                {/* <PageText type='bodyTitle'>{headerText}</PageText> */}
                            </motion.div>
                        </div>
                    </div>
                    : null
            }

            <div className={styles.buttonContainer}>
                <ScrollButtons
                    leftDisabled={leftDisabled}
                    leftOnClick={leftOnClick}
                    rightDisabled={rightDisabled}
                    rightOnClick={rightOnClick}
                />
            </div>
        </div>
    );
}