import styles from './ScrollButtons.module.css';
import { motion, useInView } from 'framer-motion';
import { useRef } from 'react';
import { IconComponent } from '../../Icon/IconComponent';




export const ScrollButtons = ({
    leftOnClick,
    rightOnClick,
    leftDisabled,
    rightDisabled,
}) => {

    return (
        <div className={styles.scrollButtonsContainer}>
            <motion.button
                className={`${styles.scrollButton} ${leftDisabled ? styles.disabled : ''}`}
                onClick={leftOnClick}
                disabled={leftDisabled}
                whileTap={{ scale: 1.2 }}
                transition={{ type: "spring", stiffness: 300, damping: 15 }}
            >
                <IconComponent iconType="leftChevron" />
                {/* <LeftChevron /> */}
            </motion.button>
            <motion.button
                className={`${styles.scrollButton} ${rightDisabled ? styles.disabled : ''}`}
                onClick={rightOnClick}
                disabled={rightDisabled}
                whileTap={{ scale: 1.2 }}
                transition={{ type: "spring", stiffness: 300, damping: 15 }}
            >
                <IconComponent iconType="rightChevron" />
            </motion.button>
        </div>
    );
}