import { useState, useEffect, useMemo } from 'react';
import { PortalCard } from "../../PortalPageComponent/PortalCard/PortalCard";
import { useChartConfig } from "../../../../hooks/chart-config-hook";
import { transformWithSchema } from "../../../../utils/data-transformer";
import { AreaChart } from "../../PortalChartComponent/AreaChart";
import { useDataContext } from "../../../../hooks/data-hook";
import { SkeletonComponent } from "../../../Skeletons/SkeletonComponent";
import { NoDataPortalCard } from '../../PortalPageComponent/PortalCard/NoDataPortalCard';

export const LandingPageViewsCard = () => {
    const { isDataState } = useDataContext();
    const data = isDataState.isDataFilteredByDate;

    // Define loading and no data states
    const [state, setState] = useState({
        isLoading: true,
        hasData: false,
        showNoDataMessage: false,
    });

    // Reset state when data changes
    useEffect(() => {
        setState({ isLoading: true, hasData: false, showNoDataMessage: false });
    }, [data]);

    // Memoize data aggregation and sorting logic
    const { sortedData, totalPageViewCount, avgPageViewCount, vAxisTicks } = useMemo(() => {
        const aggregatedData = data
            ? data.reduce((acc, curr) => {
                const date = curr.date;
                const pageViews = Number(curr.screenPageViews);
                if (acc[date]) {
                    acc[date].screenPageViews += pageViews;
                } else {
                    acc[date] = { ...curr, screenPageViews: pageViews };
                }
                return acc;
            }, {}) : {};

        const aggregatedDataArray = Object.values(aggregatedData);
        const sortedData = aggregatedDataArray.sort((a, b) => {
            const dateA = new Date(a.date.slice(0, 4), a.date.slice(4, 6) - 1, a.date.slice(6, 8));
            const dateB = new Date(b.date.slice(0, 4), b.date.slice(4, 6) - 1, b.date.slice(6, 8));
            return dateA - dateB;
        });

        const validDays = sortedData.filter(day => day.screenPageViews && !isNaN(Number(day.screenPageViews)));
        const totalPageViewCount = validDays.reduce((acc, curr) => acc + Number(curr.screenPageViews), 0);
        const avgPageViewCount = validDays.length > 0 ? totalPageViewCount / validDays.length : 0;

        const minValue = 0;
        const maxValue = Math.ceil(Math.max(...validDays.map(day => Number(day.screenPageViews))) / 20) * 20;
        const stepSize = 100;

        const vAxisTicks = [];
        for (let i = minValue; i <= maxValue; i += stepSize) {
            vAxisTicks.push(i);
        }

        return { aggregatedData, sortedData, validDays, totalPageViewCount, avgPageViewCount, vAxisTicks };
    }, [data]);

    // Move the `useChartConfig` hook outside the memoization block
    const { config: areaChartOptions } = useChartConfig(
        'AreaChart',
        '',
        '',
        'Views',
        { minValue: 0 },
        ['#3366CC'],
        false,
        true,
        true,
        vAxisTicks
    );

    let areaChartData = null;
    if (sortedData.length > 0) {
        try {
            areaChartData = transformWithSchema(sortedData, 'pageViewSnapshot');
        } catch (error) {
            console.error(`Error transforming data: ${error.message}`);
        }
    }

    // Handle loading and no data logic
    useEffect(() => {
        if (data && data.length > 0) {
            setState({ isLoading: false, hasData: true, showNoDataMessage: false });
        } else {
            const timer = setTimeout(() => {
                setState(prevState => ({ ...prevState, isLoading: false, showNoDataMessage: true }));
            }, 2000);

            return () => clearTimeout(timer); // Cleanup timer on unmount
        }
    }, [data]);
console.log('landing page views card',sortedData)
    // While loading, show skeleton component
    if (state.isLoading) {
        return <SkeletonComponent height="33rem" width="40rem" count={1} />;
    }

    // If loading is finished and no data is found, show a "No Data" message
    if (!state.hasData && state.showNoDataMessage) {
        return <NoDataPortalCard dataTitle="Total Page Views"/>;
    }

    // Render the card if we have data
    return (
        totalPageViewCount && avgPageViewCount && areaChartData !== null ? (
            <PortalCard
                cardTitle="Total Page Views"
                cardData={totalPageViewCount.toLocaleString()}
                cardFooter={`${parseInt(avgPageViewCount)} page views per day on average`}
                toolTipText="The number of web pages your users viewed. Repeated views of a single page or screen are counted."
            >
                {areaChartData ? (
                    <AreaChart data={areaChartData} options={areaChartOptions} />
                ) : (
                    <div>No chart data available.</div>
                )}
            </PortalCard>
        ) : (
            <SkeletonComponent height="33rem" width="40rem" count={1} />
        )
    );
};
