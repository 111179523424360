import { PageText } from '../../../components/Text/Text';
import styles from './not-found.module.css'

const NotFoundPage = () => {

    return (
        <div className={styles.notFoundPageContainer}>
            <div className={styles.notFoundPageWrapper}>
                <div className={styles.notFoundPage}>
                    <img src={`/assets/image/gif/look-around-white.gif`} alt='lg white logo looking around' className={styles.gifLogo} />
                    <PageText color='white' type='heroTitle'>404 Page Not Found</PageText>
                    <PageText color='white' type='bodyTitle'>Looks like something went wrong</PageText>
                </div>
            </div>
        </div>
    );
};
export default NotFoundPage;
