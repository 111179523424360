import { SignupForm } from '../../../components/AuthComponent/Signup/SignupForm';
import { AuthTemplate } from '../../../layout/Auth/AuthTemplate';

const SignUpPage = () => {

    return (
        <AuthTemplate
            formTitle="LG Product Guide"
            formSubtitle="Submit your sign up request"
            form={<SignupForm />}
            footerCTAText="Already a member?"
            footerCTALink="../login"
            footerCTALinkText="Sign in."
        />
    );
};

export default SignUpPage;
