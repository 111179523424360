import { PageText } from "../../Text/Text";
import styles from "./ResourceCards.module.css";
import { LinkComponent } from "../../Links/LinkComponent";
import { AnimatedCheckmark } from "../../AnimatedCheckmark/AnimatedCheckmark";
import { AnimatedComponent } from "../../../hooks/use-framer-motion";
import { IconComponent } from "../../Icon/IconComponent";

export const ResourceCards = ({ title, description, icon }) => {

    return (
        <AnimatedComponent
            type="wipeEffect"
            directionStart="left"
            delay={0.1}
        >
            <div className={styles.resourceCard}>
                <AnimatedComponent
                    type="wipeEffect"
                    directionStart="left"
                    delay={0.2}
                >
                    <div className={styles.qText}>
                        <PageText type='bodyCalloutTitle'>{title}</PageText>
                        <div className={styles.qTextDescription}>
                             <PageText type='bodyCallout'>{description}</PageText>
                        </div>
                       
                    </div>
                </AnimatedComponent>
                <AnimatedComponent
                    type="wipeEffect"
                    directionStart="left"
                    delay={0.2}
                >
                    <div className={styles.qIcon}>
                        <IconComponent iconType={icon} />
                    </div>
                </AnimatedComponent>


            </div>
        </AnimatedComponent>
    );
}