import { useDataContext } from "../../../../hooks/data-hook";

import { ColumnChart } from "../../PortalChartComponent/ColumnChart";
import { useChartConfig } from "../../../../hooks/chart-config-hook";

import { PortalCard } from "../../PortalPageComponent/PortalCard/PortalCard";
import { categorizePagePath } from "../../../../utils/helper-functions";
import { SkeletonComponent } from "../../../Skeletons/SkeletonComponent";
import { NoDataPortalCard } from "../../PortalPageComponent/PortalCard/NoDataPortalCard";
import { useState, useEffect } from 'react';

export const LandingPageCategoryByPageViewsBarChart = () => {
    const { isDataState } = useDataContext();
    const data = isDataState.isDataFilteredByDate;  // Use filtered search data from context
    
     // Define loading and no data states
     const [state, setState] = useState({
        isLoading: true,
        hasData: false,
        showNoDataMessage: false,
    });

    // Reset state when data changes
    useEffect(() => {
        setState({ isLoading: true, hasData: false, showNoDataMessage: false });
    }, [data]);

      // Handle loading and no data logic
  useEffect(() => {
    if (data && data.length > 0) {
        setState({ isLoading: false, hasData: true, showNoDataMessage: false });
    } else {
        const timer = setTimeout(() => {
            setState(prevState => ({ ...prevState, isLoading: false, showNoDataMessage: true }));
        }, 2000);

        return () => clearTimeout(timer); // Cleanup timer on unmount
    }
}, [data]);
    
    const { config: barChartOptions } = useChartConfig(
        'ColumnChart', 
        '', 
        'Landing Page Type',
        'Views',
        { minValue: 0 },
        ['#3366CC'],
        false, // Show legend
        true, // Show X-axis labels
        true,
    );


    // Step 1: Filter relevant events with 'screenPageViews'
      // Only proceed with data processing if data is valid
      const filteredEvents = data && Array.isArray(data)
      ? data.filter(event => event.screenPageViews && !isNaN(Number(event.screenPageViews)))
      : [];

    // const filteredEvents = data.filter(event => event.screenPageViews && !isNaN(Number(event.screenPageViews)));

    // Step 2: Aggregate screenPageViews by categorized page paths
    const eventPageMap = new Map();

    filteredEvents.forEach(item => {
        const pageCategory = categorizePagePath(item.pagePath); // Categorize the page path
        const pageViews = Number(item.screenPageViews);

        if (!eventPageMap.has(pageCategory)) {
            eventPageMap.set(pageCategory, { pageCategory, totalPageViews: 0 });
        }

        const pageData = eventPageMap.get(pageCategory);
        pageData.totalPageViews += pageViews; // Aggregate page views for the category
    });

    // Step 3: Sort the aggregated data in descending order by totalPageViews
    const sortedPages = Array.from(eventPageMap.values()).sort((a, b) => b.totalPageViews - a.totalPageViews);

    // Step 4: Calculate total and average page view count
    const totalPageViewCount = sortedPages.reduce((acc, curr) => acc + curr.totalPageViews, 0);
    const avgPageViewCount = sortedPages.length > 0 ? totalPageViewCount / sortedPages.length : 0;

    // Step 5: Transform the data for BarChart
    const chartData = [
        ['Page Category', 'Page Views'],
        ...sortedPages.map(item => [item.pageCategory, item.totalPageViews])
    ];

    // Step 6: Identify the most popular page category
    const mostPopularCategory = sortedPages[0] ? sortedPages[0].pageCategory : 'Unknown Category';
    const mostPopularCategoryViews = sortedPages[0] ? sortedPages[0].totalPageViews : 0;


// Handle loading and no data logic
    useEffect(() => {
        if (data && data.length > 0) {
            setState({ isLoading: false, hasData: true, showNoDataMessage: false });
        } else {
            const timer = setTimeout(() => {
                setState(prevState => ({ ...prevState, isLoading: false, showNoDataMessage: true }));
            }, 2000);

            return () => clearTimeout(timer); // Cleanup timer on unmount
        }
    }, [data]);

    // While loading, show skeleton component
    if (state.isLoading) {
        return <SkeletonComponent height="33rem" count={1} />;
    }

    // If loading is finished and no data is found, show a "No Data" message
    if (!state.hasData && state.showNoDataMessage) {
  
        return <NoDataPortalCard dataTitle="Popular Types of Pages"/>;
    }


    return (
mostPopularCategory && mostPopularCategoryViews && chartData !== null ?

        <PortalCard
        toolTipText="This chart displays the most viewed types of pages on your site, categorized by their purpose. These include 'Homepage', 'All Category Pages', 'Category Pages', 'Product Pages', 'Resource Pages', 'Step-Up Charts', and 'Product List Builder'. Each category represents a different area of user interest and engagement on your site."


            cardTitle="Popular Types of Pages"
            cardFooter={ `Most popular page type: "${mostPopularCategory}" with ${mostPopularCategoryViews.toLocaleString()} views.`}
        >
            {
                chartData.length > 1 ? (
                    <ColumnChart data={chartData} options={barChartOptions} />  // Use BarChart to display the data
                ) : (
                    <div>No chart data available.</div>
                )
            }

        </PortalCard>
        : <SkeletonComponent height="33rem" count={1} />
    );
};