import styles from './StickyNavigationBar.module.css';
import { useState, useRef, useEffect } from 'react';
import useIntersectionObserver from '../../hooks/use-intersection-observer';
import { PageText } from '../Text/Text';
import { capitalizeFirstLetterEachWord } from '../../utils/helper-functions';
import { GridSystem } from '../GridSystem/GridSystem';

export const StickyNavigationBar = ({ data }) => {
  const [activeSection, setActiveSection] = useState(null);
  const isManualScroll = useRef(false); // Flag to track if scrolling is manual
  const scrollTimeout = useRef(null); // Reference to a timeout for scroll detection

  useIntersectionObserver((newActiveSection) => {
    // If it's a manual scroll, do not update the active section
    if (!isManualScroll.current) {
      setActiveSection(newActiveSection);
    }
  });

  const handleClick = (event, categoryIndex) => {
    event.preventDefault();
    const targetElement = document.getElementById(`category-${categoryIndex}`);
    
    if (targetElement) {
      isManualScroll.current = true; // Set manual scroll flag
      setActiveSection(`category-${categoryIndex}`); // Manually set the active section

      // Smooth scroll to the target element
      window.scrollTo({
        top: targetElement.offsetTop,
        behavior: 'smooth'
      });

      // Clear any previous timeout
      if (scrollTimeout.current) {
        clearTimeout(scrollTimeout.current);
      }

      // Set a timeout to reset the manual scroll flag after scrolling stops
      scrollTimeout.current = setTimeout(() => {
        isManualScroll.current = false;
      }, 300); // Adjust as needed for scroll time
    }
  };

  // Clean up the timeout when the component is unmounted
  useEffect(() => {
    return () => {
      if (scrollTimeout.current) {
        clearTimeout(scrollTimeout.current);
      }
    };
  }, []);

  const activeStyle = {
    fontWeight: '600',
    borderBottom: '3px solid #EA1917',
  };

  return (
    <GridSystem containerBackgroundColor='#E6E1D6'>
      <div className={styles.contentWrapper}>
        <nav className={styles.stickyNavContainer}>
          <div className={styles.stickyNavWrapper}>
            <div className={styles.stickyNav}>
              {Object.keys(data).map((category, categoryIndex) => (
                <a
                  key={categoryIndex}
                  href={`#category-${categoryIndex}`}
                  onClick={(e) => handleClick(e, categoryIndex)}
                  style={activeSection === `category-${categoryIndex}` ? activeStyle : {}}
                >
                  <PageText type='stickyNavTitleText'>
                    {capitalizeFirstLetterEachWord(category)}
                  </PageText>
                </a>
              ))}
            </div>
          </div>
        </nav>
      </div>
    </GridSystem>
  );
};
