import { PieChart } from "../../PortalChartComponent/PieChart";
import { PortalCard } from "../../PortalPageComponent/PortalCard/PortalCard";
import { transformWithSchema } from "../../../../utils/data-transformer";
import { useDataContext } from "../../../../hooks/data-hook";
import { useChartConfig } from "../../../../hooks/chart-config-hook";
import { formatChartLabel } from "../../../../utils/helper-functions";
import { SkeletonComponent } from "../../../Skeletons/SkeletonComponent";
import { NoDataPortalCard } from "../../PortalPageComponent/PortalCard/NoDataPortalCard";
import { useState, useEffect } from 'react';

export const UsersByDeviceCategoryPieChart = () => {
    const { isDataState } = useDataContext();
    const pieData = isDataState.technicalDataFilteredByDate;

    // Define loading and no data states
    const [state, setState] = useState({
        isLoading: true,
        hasData: false,
        showNoDataMessage: false,
    });

    // Reset state when data changes
    useEffect(() => {
        setState({ isLoading: true, hasData: false, showNoDataMessage: false });
    }, [pieData]);

  
    const { config: pieChartOptions } = useChartConfig('PieChart', '');


    // Aggregate data by device category
    const aggregatedData = pieData && pieData.length !== 0
    ? pieData.reduce((acc, item) => {
        if (item.deviceCategory && !isNaN(Number(item.totalUsers))) {
            const category = item.deviceCategory;
            const totalUsers = Number(item.totalUsers);

            if (acc[category]) {
                acc[category] += totalUsers;
            } else {
                acc[category] = totalUsers;
            }
        }
        return acc;
    }, {}):{};

    // Transform aggregated data for chart
    const transformedData = Object.keys(aggregatedData).map(deviceCategory => ({
        deviceCategory: formatChartLabel(deviceCategory),
        totalUsers: aggregatedData[deviceCategory],
    }));

    // Find category with the most users and calculate percentage
    //  const totalUsers = Object.values(aggregatedData).reduce((acc, curr) => acc + curr, 0);
    //  const maxCategory = Object.keys(aggregatedData).reduce((max, category) =>
    //      aggregatedData[category] > aggregatedData[max] ? category : max
    //  , Object.keys(aggregatedData)[0]);

    //  const maxCategoryUsers = aggregatedData[maxCategory];
    //  const percentage = ((maxCategoryUsers / totalUsers) * 100).toFixed(2);
  // Handle loading and no data logic
  useEffect(() => {
    if (pieData && pieData.length > 0) {
        setState({ isLoading: false, hasData: true, showNoDataMessage: false });
    } else {
        const timer = setTimeout(() => {
            setState(prevState => ({ ...prevState, isLoading: false, showNoDataMessage: true }));
        }, 2000);

        return () => clearTimeout(timer); // Cleanup timer on unmount
    }
}, [pieData]);


    let chartData = null;
    try {
        chartData = transformWithSchema(transformedData, 'technicalActivityDeviceCategorySnapshot');
    } catch (error) {
        console.error(`Error transforming data: ${error.message}`);
    }
    // While loading, show skeleton component
    if (state.isLoading) {
        return <SkeletonComponent height="33rem" count={1} />;
    }

    // If loading is finished and no data is found, show a "No Data" message
    if (!state.hasData && state.showNoDataMessage) {
        return <NoDataPortalCard dataTitle="Users by Device Category" />;
    }



    return (
        <PortalCard
            cardTitle="User Distribution by Device Category"
            toolTipText="This chart categorizes users based on the type of device they use to visit your site, such as Desktop, Tablet, or Mobile. Understanding the breakdown helps optimize the user experience for each device type."
        // cardFooter={`${percentage}% of users visit via ${formatChartLabel(maxCategory)} devices.`}
        >
            <PieChart data={chartData} options={pieChartOptions} />

        </PortalCard>
    );
}
