

import { PortalLeaderBoard } from "../../PortalPageComponent/PortalLeaderBoard/PortalLeaderBoard";
import { PortalLeaderBoardRow } from "../../PortalPageComponent/PortalLeaderBoard/PortalLeaderBoardRow";
import { useDataContext } from "../../../../hooks/data-hook";
import { SkeletonComponent } from "../../../Skeletons/SkeletonComponent";
import { NoDataPortalCard } from "../../PortalPageComponent/PortalCard/NoDataPortalCard";
import { useState, useEffect } from 'react';

export const SearchQueriesUsedWhenProductSelectedLeaderBoard = ({ limit = 10 }) => {
    const { isDataState } = useDataContext(); // Access filtered data from context
    const data = isDataState.searchDataFilteredByDate;

    
        // Define loading and no data states
        const [state, setState] = useState({
            isLoading: true,
            hasData: false,
            showNoDataMessage: false,
        });

         // Reset state when data changes
    useEffect(() => {
        setState({ isLoading: true, hasData: false, showNoDataMessage: false });
    }, [data]);

   


    const targetEvents = [ 'SEARCHED_PRODUCT_SELECTED'];


  // Step 1: Filter eventPageOverview for target events
  const filteredEvents = data ? data.filter(event => targetEvents.includes(event.eventName)):[];


    const productMap = new Map();

    filteredEvents.forEach(item => {
        const productName = item['customEvent:searchQuery'] || 'Unknown query'; 

        if (!productMap.has(productName)) {
            productMap.set(productName, { productName, totalAddToList: 0 });
        }
        const product = productMap.get(productName);
        product.totalAddToList += Number(item.eventCount);
    });



    // Step 3: Convert Map to array and sort by totalAddToList in descending order
    const sortedProducts = 
    Array.from(productMap.values())
    .sort((a, b) => b.totalAddToList - a.totalAddToList)
    .slice(0, limit);

     // Handle loading and no data logic
     useEffect(() => {
        if (filteredEvents && filteredEvents.length !== 0) {
            setState({ isLoading: false, hasData: true, showNoDataMessage: false });
        } else {
            const timer = setTimeout(() => {
                setState(prevState => ({ ...prevState, isLoading: false, showNoDataMessage: true }));
            }, 2000);

            return () => clearTimeout(timer); // Cleanup timer on unmount
        }
    }, [data]);

          // While loading, show skeleton component
          if (state.isLoading) {
            return <SkeletonComponent height="33rem" count={1} />;
        }
    
        // If loading is finished and no data is found, show a "No Data" message
        if (!state.hasData && state.showNoDataMessage) {
            return <NoDataPortalCard dataTitle="Top Search Queries Leading to Product Visits" />;
        }

    return (
        <PortalLeaderBoard title="Top Search Queries Leading to Product Visits">
            <ul>
                {sortedProducts.map((product, idx) => (
                    <li key={idx}>
                        <PortalLeaderBoardRow
                            rank={idx + 1}
                            dimension={product.productName}
                            metric={product.totalAddToList}
                        />
                    </li>
                ))}
            </ul>
        </PortalLeaderBoard>
    );
};