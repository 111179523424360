import { useDataContext } from "../../../../hooks/data-hook"
import { PortalLeaderBoard } from "../../PortalPageComponent/PortalLeaderBoard/PortalLeaderBoard";
import { PortalLeaderBoardRow } from "../../PortalPageComponent/PortalLeaderBoard/PortalLeaderBoardRow";
import { SkeletonComponent } from "../../../Skeletons/SkeletonComponent";
import { NoDataPortalCard } from "../../PortalPageComponent/PortalCard/NoDataPortalCard";
import { useState, useEffect } from 'react';

export const EventsByLocationLeaderboard = ({ limit = 10 }) => {
    const { isDataState } = useDataContext();
    const data = isDataState.isDataFilteredByDate;  // Use the filtered event data

    // Define loading and no data states
    const [state, setState] = useState({
        isLoading: true,
        hasData: false,
        showNoDataMessage: false,
    });

    // Reset state when data changes
    useEffect(() => {
        setState({ isLoading: true, hasData: false, showNoDataMessage: false });
    }, [data]);



    const targetEvents = [
        'SEARCHED_PRODUCT_SELECTED', 'SEARCHED_PRODUCT_ADDED', 'LIST_PRINTED', 'RESOURCE_CLICKED', 'PRODUCT_ADDED',
    ];

    // const filteredEvents = data.filter(event => targetEvents.includes(event.eventName));
    const filteredEvents = data && Array.isArray(data)
        ? data.filter(event => targetEvents.includes(event.eventName))
        : [];


    const eventsMap = new Map();

    // Step 1: Aggregate page views by city and country
    filteredEvents.forEach((item) => {
        const key = `${item.country}-${item.city}-${item.region}`;

        if (!eventsMap.has(key)) {
            eventsMap.set(key, {

                country: item.country,
                city: item.city,
                region: item.region,
                event: item.eventName,
                totalEvents: 0,
            });
        }
        const current = eventsMap.get(key);
        current.totalEvents += Number(item.eventCount); // Aggregate page views by location
    });

    const sortedEvents = Array.from(eventsMap.values()).sort((a, b) => b.totalEvents - a.totalEvents);
    const limitedLocations = sortedEvents.slice(0, limit);

    // Handle loading and no data logic
    useEffect(() => {
        if (filteredEvents && filteredEvents.length > 0) {
            setState({ isLoading: false, hasData: true, showNoDataMessage: false });
        } else {
            const timer = setTimeout(() => {
                setState(prevState => ({ ...prevState, isLoading: false, showNoDataMessage: true }));
            }, 2000);

            return () => clearTimeout(timer); // Cleanup timer on unmount
        }
    }, [data]);


    if (state.isLoading) {
        return <SkeletonComponent height="33rem" count={1} />;
    }

    // If loading is finished and no data is found, show a "No Data" message
    if (!state.hasData && state.showNoDataMessage) {
        return <NoDataPortalCard dataTitle="Event Count by Location" />;
    }


    return (

        <PortalLeaderBoard title={`Event Count by Location`}>

            <ul>
                {limitedLocations.map((e, idx) => (
                    <li key={idx}>
                        <PortalLeaderBoardRow
                            rank={idx + 1}
                            dimension={`${e.country}, ${e.city}`} // Display city and country
                            metric={e.totalEvents} // Display total page views
                        />
                    </li>
                ))}
            </ul>


        </PortalLeaderBoard>
    )

}