import { PageText } from '../../../components/Text/Text';
import styles from './EmptyListPage.module.css';
import { CountBubble } from '../../../components/CountBubble/CountBubble';
import { LGComponent } from '../../../components/Character/LGComponent';

import { Button } from '../../../components/Button/Button';
import { GridSystem } from '../../../components/GridSystem/GridSystem';
import { motion } from "framer-motion";
import { AnimatedComponent, AnimatedImage } from '../../../hooks/use-framer-motion';
import { LinkComponent } from '../../../components/Links/LinkComponent';

const listVariants = {
    hidden: { opacity: 0 },
    visible: {
        opacity: 1,
        transition: {
            staggerChildren: 0.1 // Adjust for timing between children
        }
    }
};

const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
};



export const EmptyListPage = () => {

    const instructions = [
        {
            step: 'Step 1',
            title: 'Add Products to Your List',
            descriptionLong: 'Browse our extensive product catalog and add appliances that suit your customer’s needs to your personalized list with just one click.',
            descriptionShort: `Search for products and click “Add to List."`
        },
        {
            step: 'Step 2',
            title: 'Visit the Product List Builder Page',
            descriptionLong: "Go to the Product List Builder page to review, manage, and organize your selected home appliances.",
            descriptionShort: "Navigate to the “Product List Builder” page to review your selections or make updates."
        },
        {
            step: 'Step 3',
            title: 'Print Your List',
            descriptionLong: "Click “Print My List” and follow the instructions in the print dialog on your device.",
            descriptionShort: "Click “Print My List” and follow the on-screen instructions."
        },
    ];
  
    return (
        <div className={styles.mainContainer}>
            <div className={styles.mobile}>
                <GridSystem >
                    <div className={styles.contentWrapper}>
                        <div className={styles.emptyListHeader}>
                            <AnimatedComponent type="wipeEffect" directionStart='left' delay={0.1}>
                                <div className={styles.headerSubtitle}>
                                    <PageText type='heroSubtitle'>Tools</PageText>
                                </div>
                            </AnimatedComponent>
                            <AnimatedComponent type="bounceEffect">
                                <div className={styles.headerTitle}>
                                    <PageText type='heroTitle'>Product List Builder</PageText>
                                </div>
                            </AnimatedComponent>
                        </div>
                    </div>
                </GridSystem>
                <GridSystem gridType='spread'>
                    <div className={styles.contentWrapper}>
                        <div className={styles.descriptionWrapper}>
                            <div className={styles.description}>
                                <PageText type='bodyDescription'>The Product List Builder allows you to effortlessly create custom lists based on your customer's needs and preferences.</PageText>
                            </div>
                        </div>
                    </div>
                </GridSystem>
                <div className={styles.introductionGridMobile}>
                    <div className={styles.backgroundScreen} >
                        <div className={styles.instructionsWrapper}>
                            <motion.div
                                variants={listVariants}
                                initial="hidden"
                                animate="visible"
                                className={styles.instructions}>
                                <GridSystem>
                                    {instructions && instructions.map((e, idx) => {
                                        return (
                                            <motion.div
                                                variants={itemVariants}
                                                className={styles.stepsWrapper} key={idx}>
                                                <div className={styles.stepCount}>
                                                    <CountBubble itemCount={idx + 1} />
                                                </div>
                                                <div className={styles.stepDescription}>
                                                    <PageText type='bodyDescription'>{e.descriptionShort}</PageText>
                                                </div>
                                            </motion.div>
                                        );
                                    })}
                                </GridSystem>
                            </motion.div>
                            <div className={styles.callout}>
                                <GridSystem>
                                    <PageText type='bodyDescription'>It's quick and simple! Start exploring our product guide and build your list today.</PageText>
                                </GridSystem>
                            </div>
                        </div>
                    </div>

                    <div className={styles.character}>
                        <div className={styles.charcterImageWrapper}>
                            <LGComponent type='boyFullCrop' />
                        </div>
                    </div>

                    <div className={styles.featureButtons}>
                        <GridSystem>
                            <div className={styles.contentWrapper}>
                                <div className={styles.mobileButton}>
                                    <LinkComponent href={"/appliances"}>
                                        <Button buttonStyleType='primaryAction'>Home Appliances</Button>
                                    </LinkComponent>
                                </div>

                                <div className={styles.desktopButton}>
                                    <LinkComponent href={"/appliances"}>
                                        <Button buttonStyleType='primaryAction'>Home Appliances</Button>
                                    </LinkComponent>

                                </div>
                            </div>
                        </GridSystem>

                    </div>

                </div>



            </div>
            <div className={styles.desktop}>
                <GridSystem >
                    <div className={styles.contentWrapper}>
                        <div className={styles.desktopEmptyListGrid}>
                            <div className={styles.emptyListHeader}>
                                <div className={styles.headerSubtitle}>
                                    <PageText type='heroSubtitle'>Tools</PageText>
                                </div>
                                <AnimatedComponent type="bounceEffect">
                                    <div className={styles.headerTitle}>
                                        <PageText type='heroTitle'>Product List Builder</PageText>
                                    </div>
                                </AnimatedComponent>
                                <AnimatedComponent type="wipeEffect" directionStart='left' delay={0.1}>

                                </AnimatedComponent>
                              
                                <AnimatedComponent type="wipeEffect" directionStart='left' delay={0.3}>
                                    <div className={styles.descriptionWrapper}>
                                        <div className={styles.headerDescription}>
                                            <PageText type='heroDescription'>Welcome to the Product List Builder—your ultimate tool for creating personalized product lists. </PageText>
                                        </div>
                                    </div>
                                </AnimatedComponent>
                            </div>
                            <div className={styles.instructionsWrapper}>
                                <motion.div
                                    variants={listVariants}
                                    initial="hidden"
                                    animate="visible"
                                    className={styles.instructions}>

                                    {instructions && instructions.map((e, idx) => {
                                        return (
                                            <motion.div
                                                variants={itemVariants}
                                                className={styles.stepsWrapper} key={idx}>
                                                <div className={styles.stepCount}>
                                                    <CountBubble backgroundColor='white' color='black' itemCount={idx + 1} />
                                                </div>
                                                <div className={styles.stepDescription}>
                                                    <PageText type='bodyCallout'>{e.descriptionLong}</PageText>
                                                </div>
                                            </motion.div>
                                        );
                                    })}
                                </motion.div>
                            </div>
                            <div className={styles.lgMan}>
                                <div className={styles.lgGirl}>
                                    <AnimatedImage
                                        type="wipeEffect" directionStart='left' delay={.3}
                                        src={`/assets/image/backgrounds/builder/lg-print-handoff.webp`} />
                                </div>
                            </div>
                            <div className={styles.desktopCallout}>
                                <PageText type='heroDescription'>Whether you're helping customers compare appliances or curating a selection for a client, this feature empowers you to compile, organize, and print a tailored list of LG home appliance products in just a few easy steps.</PageText>
                            </div>
                            <div className={styles.featureButtons}>
                                <div className={styles.desktopButton}>
                                    <LinkComponent href={"/appliances"}>
                                        <Button buttonStyleType='primaryAction'> Discover home appliances</Button>
                                    </LinkComponent>
                                </div>
                            </div>
                        </div>
                    </div>
                </GridSystem>
            </div>
        </div>
    )
}