import styles from './modelTransitionsPage.module.css'
import { GridSystem } from "../../../components/GridSystem/GridSystem";
import { HeroComponent } from "../../../components/HeroComponent/HeroComponent";
import { ImageWithSkeleton } from '../../../components/Skeletons/ImageSkeleton';

const ModelTransitionsPage = () => {

    return (
        <>
            <HeroComponent
                title="Model transitions"
                subtitle="Resources"
                description={'Review model transition charts'}
                imgAlt="lg studio styler and washtower lifestyle wall"
                imgSrc="/assets/image/backgrounds/resources/transition-hero.png"
            />

            <GridSystem containerBackgroundColor='#E6E1D6'>
                <div className={styles.contentWrapper}>
                    <div className={styles.chartBodyContainer}>
                        <div className={styles.chartImageWrapper}>
                            <ImageWithSkeleton
                                src='/assets/image/model-transitions/transition-chart-q3.webp'
                                alt={`Model Transitions q3`}
                                skeletonHeight="500px"
                            />
                            <ImageWithSkeleton
                                src='/assets/image/model-transitions/transition-chart-q4.webp'
                                alt={`Model Transitions q4`}
                                skeletonHeight="500px"
                            />
                        </div>
                    </div>
                </div>
            </GridSystem>
        </>
    )
}

export default ModelTransitionsPage;