import { PortalPage } from "../../components/PortalComponent/PortalPageComponent/PortalPage/PortalPage";
import { ProductDirectoryTable } from "../../components/PortalComponent/PortalTableComponent/ProductDirectoryTable";

const ProductDirectoryPage = () => {

    return (
        <PortalPage
            pageTitle='Product Directory'
            pageDescription='All home appliance featured on the product guide websites'
            breadcrumb='Product Directory'
            breadcrumbDirectory="Products"
            breadcrumbDirectoryLink="/portal/product-directory"
            breadcrumbLink="/portal/product-directory"
        >
            
            <ProductDirectoryTable />
        </PortalPage>

    );
}

export default ProductDirectoryPage;