import { PageText } from "../../Text/Text";
import styles from "./BenefitCards.module.css";
import { LinkComponent } from "../../Links/LinkComponent";
import { AnimatedCheckmark } from "../../AnimatedCheckmark/AnimatedCheckmark";
import { AnimatedComponent } from "../../../hooks/use-framer-motion";

export const BenefitCards = ({ title, link, description, shortDescription }) => {

    return (
        <div className={styles.benefitCard}>
            
            <div className={styles.iconWrapper}>
                <LinkComponent href={link}>
                    <AnimatedCheckmark />
                </LinkComponent>
            </div>
            <div className={styles.benefitCardText}>
                <AnimatedComponent
                    type="wipeEffect"
                    directionStart="left"
                    delay={0.1}
                >
                    <span className={styles.benefitTitle}>
                        <LinkComponent href={link}>
                            <PageText type='bodyCalloutBoldTitle'>{title}</PageText>
                        </LinkComponent>
                    </span>
                </AnimatedComponent>
                <AnimatedComponent
                    type="wipeEffect"
                    directionStart="left"
                    delay={0.1}
                >
                    <div className={styles.longBenefitText}>
                        <PageText type='bodyCallout'>{description}</PageText>
                    </div>
                    <div className={styles.shortBenefitText}>
                        <PageText type='bodyCallout'>{shortDescription}</PageText>
                    </div>
                </AnimatedComponent>
            </div>
        </div>
    );
}