import { PageText } from '../../Text/Text';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { HashLink } from 'react-router-hash-link';
import { motion } from 'framer-motion';
import styles from './ProductCategoryCard.module.css';


export const ProductCategoryCard = ({
    subcategory,
    hashLinkPath,
    subcategoryImagePath,

}) => {


    return (

        <HashLink to={hashLinkPath}>
              <motion.div
               whileHover={{
                scale: 1.02,
                transition: {
                    duration: .2,
                },
                boxShadow: "0px 10px 15px rgba(0, 0, 0, 0.2)" // Added boxShadow
            }}
            whileTap={{
                scale: 1,
                transition: {
                    duration: .3,
                }
            }}
            transition={{ duration: 0.3 }} 
            className={styles.categoryCardContainer}>
                
                    <div className={styles.imageWrapper}>
                        {
                            <img loading='lazy' src={`${process.env.REACT_APP_AWS_URL_IMAGE}/${subcategoryImagePath}`} alt={subcategory} />||  <Skeleton /> 
                        }
                     
                    </div>  
                

                <div className={styles.titleWrapper}>
                    <PageText type='productCardTitle'>{subcategory || <Skeleton />}</PageText>

                </div>

            </motion.div>
        </HashLink>
    );
}

