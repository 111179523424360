import { PieChart } from "../../PortalChartComponent/PieChart";
import { PortalCard } from "../../PortalPageComponent/PortalCard/PortalCard";
import { transformWithSchema } from "../../../../utils/data-transformer";
import { useDataContext } from "../../../../hooks/data-hook";
import { useChartConfig } from "../../../../hooks/chart-config-hook";
import { formatChartLabel } from "../../../../utils/helper-functions";
import { SkeletonComponent } from "../../../Skeletons/SkeletonComponent";
import { NoDataPortalCard } from "../../PortalPageComponent/PortalCard/NoDataPortalCard";
import { useState, useEffect } from 'react';

export const PrintProductListPostActionPieChart = () => {
    const { isDataState } = useDataContext();
    const pieData = isDataState.printDataFilteredByDate;
    const { config: pieChartOptions } = useChartConfig('PieChart', '');

    const targetEvents = ['LIST_PRINTED'];

    // Define loading and no data states
    const [state, setState] = useState({
        isLoading: true,
        hasData: false,
        showNoDataMessage: false,
    });

    // Reset state when data changes
    useEffect(() => {
        setState({ isLoading: true, hasData: false, showNoDataMessage: false });
    }, [pieData]);



    // Step 1: Filter for 'Print_Product_List' events
    const filteredEvents = pieData && pieData.filter(
        event => targetEvents.includes(event.eventName)
    );

    // Step 2: Aggregate eventCount by postPrintListAction
    const aggregatedData = filteredEvents 
    ? filteredEvents.reduce((acc, item) => {
        const postPrintListAction = item['customEvent:postPrintListAction'] || 'Unknown Action';
        const eventCount = Number(item.eventCount);

        if (acc[postPrintListAction]) {
            acc[postPrintListAction] += eventCount;
        } else {
            acc[postPrintListAction] = eventCount;
        }
        return acc;
    }, {}):{};

    // Step 3: Find the category with the most users and calculate percentage
    const totalEvents = Object.values(aggregatedData).reduce((acc, curr) => acc + curr, 0);
    const maxCategory = Object.keys(aggregatedData).reduce((max, category) =>
        aggregatedData[category] > aggregatedData[max] ? category : max,
        Object.keys(aggregatedData)[0]
    );
    const maxCategoryEvents = aggregatedData[maxCategory];
    const percentage = ((maxCategoryEvents / totalEvents) * 100).toFixed(2);

    // Step 4: Transform aggregated data into a format suitable for the pie chart
    const transformedData = Object.keys(aggregatedData).map(action => ({
        postPrintListAction: formatChartLabel(action), // Format label if needed
        eventCount: aggregatedData[action],
    }));

    // Step 5: Use the schema for the pie chart
    let chartData = null;
    try {
        chartData = transformWithSchema(transformedData, 'printActivityOverview'); // Use the correct schema
    } catch (error) {
        console.error(`Error transforming data: ${error.message}`);
    
    }

    
        // Handle loading and no data logic
        useEffect(() => {
            if (filteredEvents && filteredEvents.length !== 0) {
                setState({ isLoading: false, hasData: true, showNoDataMessage: false });
            } else {
                const timer = setTimeout(() => {
                    setState(prevState => ({ ...prevState, isLoading: false, showNoDataMessage: true }));
                }, 2000);
    
                return () => clearTimeout(timer); // Cleanup timer on unmount
            }
        }, [pieData]);

         // While loading, show skeleton component
         if (state.isLoading) {
            return <SkeletonComponent height="33rem" count={1} />;
        }
    
        // If loading is finished and no data is found, show a "No Data" message
        if (!state.hasData && state.showNoDataMessage) {
            return <NoDataPortalCard dataTitle="Post Print List Actions" />;
        }
    
    

    return (
        <PortalCard
            cardTitle="Post Print List Actions"
            toolTipText="This chart illustrates the distribution of actions users take after printing a product list."
            //  toolTipText="This chart illustrates the distribution of actions users take after printing a product list. It provides insights into common next steps, such as viewing, sharing, or saving the list."
            // toolTipText="This chart shows the actions taken after printing a product list."
            cardFooter={`${percentage}% of users took the action "${formatChartLabel(maxCategory)}" after printing the product list`}
        >
           
                <PieChart data={chartData} options={pieChartOptions} />
         
        </PortalCard>
    );
};
