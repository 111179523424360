import styles from './ApplianceCard.module.css';


import { useBuilderHook } from '../../../hooks/use-builder-hooks';
import { Button } from '../../Button/Button';
import { LinkComponent } from '../../Links/LinkComponent';
import { useRetailer } from '../../../hooks/use-routing-hooks';


import { PageText } from '../../Text/Text';
import { TruncateText, NormalizeSlugs } from '../../../utils/helper-functions';




import { AddToListButton, RemoveFromListButton } from '../../Button/ProductButtons';
import { ProductImageComponent } from '../../ProductImageComponent/ProductImageComponent';
import { useResponsiveStateHook } from '../../../hooks/responsive-hook';

import { RetailerLogo } from '../../Logo/RetalierLogo';
import { motion } from 'framer-motion';

export const Card = ({ children }) => {
    return <div className={styles.card}>{children}</div>
}


export const ApplianceCard = ({ product }) => {

    const { title, subtitle, availability, image, category, store } = product;
    const { isMobile } = useResponsiveStateHook();

    const normalizedCategoryName = NormalizeSlugs(category)

    const productURL = `/appliances/${normalizedCategoryName}/${title}`;
    const { productsInList } = useBuilderHook();
    const isProductInList = productsInList.some(p => p.title === product.title);

    const Wrapper = isMobile ? 'div' : motion.div;

    return (
        
        <Card >
            <div key={title} className={styles.productCardContainer}>
                <div className={styles.productCardWrapper}>

                    <RetailerLogo store={store} />

                    {/* <ProductImageComponent className={styles.productCardImage} src={`${publicUrl}/assets/image/products/${image}`} alt={`product ${title}`} /> */}
                    {/* <div className={styles.linkWrappedProductImage}>
                        <LinkComponent href={productURL}> */}
                    <LinkComponent href={productURL}>
                        <div className={styles.linkedImageWrapper}>
                            <ProductImageComponent className={styles.productCardImage} src={`${process.env.REACT_APP_AWS_URL}/${image}`} alt={`product ${title}`} />
                        </div>
                    </LinkComponent>
                    {/* </LinkComponent>
                    </div> */}
                    <PageText type='productCardTitle'>{title}</PageText>
                    <PageText type='productCardSubtitle'>
                        <span className={styles.clampedSubtitle}>{subtitle}</span>
                        {TruncateText(subtitle)}
                    </PageText>
                    <div className={styles.buttonsWrapper}>
                        {isProductInList ?
                            <Wrapper
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                                transition={{ type: "spring", stiffness: 300, damping: 15 }}
                                style={{
                                    width: "100%"
                                }}
                            >
                                <RemoveFromListButton product={product} />
                            </Wrapper>
                            :


                            <Wrapper
                                whileHover={{ scale: 1.05 }}
                                // whileHover={{ scale: 1.05, background: "white", color: "black"}}
                                whileTap={{ scale: 0.95 }}
                                transition={{ type: "spring", stiffness: 300, damping: 15 }}
                                style={{
                                    width: "100%"
                                }}
                            >
                                <AddToListButton product={product} />
                            </Wrapper>

                        }

                        <Wrapper
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                            transition={{ type: "spring", stiffness: 300, damping: 15 }}
                            style={{
                                width: "100%"
                            }}
                        >
                            <LinkComponent href={productURL}>
                                <Button buttonStyleType="secondary" buttonTextType="action">
                                    See details
                                </Button>
                            </LinkComponent>
                        </Wrapper>

                    </div>
                </div>
            </div>

        </Card>
        // </motion.div>
    );
}
