import styles from './warranties.module.css'
import { WARRANTY_DATA } from '../../../data/STATIC_DATA';
import { PageText } from '../../../components/Text/Text';
import { GridSystem } from '../../../components/GridSystem/GridSystem';
import { HeroComponent } from '../../../components/HeroComponent/HeroComponent';
import { useResponsiveStateHook } from '../../../hooks/responsive-hook';
import { motion } from 'framer-motion';

const WarrantiesPage = () => {
    const containerVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                staggerChildren: 0.2,
                delayChildren: 0.1,
            }
        }
    };

    const itemVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: { opacity: 1, y: 0 }
    };

    const { isMobile } = useResponsiveStateHook();
    const description = isMobile
        ? "LG is committed to making sure that each of our products perform at the highest level for years to come."
        : "LG is committed to making sure that each of our products perform at the highest level for years to come. We are constantly innovating to make sure our customers can purchase with confidence, and pride ourselves in creating the most reliable appliances on the market."
    return (
        <div className={styles.warrantiesPageContainer}>
            <HeroComponent
                title="Limited warranties"
                subtitle="Resources"
                description={description}
                imgAlt="lg dad and son"
                imgSrc="/assets/image/backgrounds/resources/lg-dad-shape.webp"
            />

            <GridSystem containerBackgroundColor='#E6E1D6'>
                <div className={styles.contentWrapper}>
                    <motion.div
                        variants={containerVariants}
                        initial="hidden"
                        animate="visible"
                        className={styles.bodySection}
                    >
                        {WARRANTY_DATA.map((warranties, idx) => (
                            <motion.div
                                key={idx}
                                className={styles.warrantyCard}
                                variants={itemVariants}
                            >
                                <div className={styles.warrantyCardTitle}>
                                    <PageText type='bodySubtitle'>{warranties.warranty_category}</PageText>
                                </div>
                                <ul className={styles.warrantyItemListWrapper}>
                                    {warranties.description.map((list, idx) => (
                                        <li className={styles.warrantyItem} key={idx}>
                                            <PageText type='bodyDescription'>{list}</PageText>
                                        </li>
                                    ))}
                                </ul>
                            </motion.div>
                        ))}
                    </motion.div>
                </div>
            </GridSystem>
        </div>
    );
}
export default WarrantiesPage;