import { motion, useInView } from 'framer-motion';
import styles from './Specifications.module.css';
import { PageText } from '../../Text/Text';
import { capitalizeFirstLetterEachWord } from '../../../utils/helper-functions';
import { useRef } from 'react';
import { AnimatedComponent } from '../../../hooks/use-framer-motion';


const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
        opacity: 1,
        transition: {
            staggerChildren: 0.2,
            delayChildren: 0.1,
        }
    }
};

const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
};

export const PrintSpecifications = ({ product, print = false }) => {

    function capitalizeFirstLetter(text) {
        text = text.toLowerCase();
        return text.charAt(0).toUpperCase() + text.slice(1);
    }

    const specListStyles = print === true
        ? {
            ulStyles: styles.printUl,
            liStyles: styles.printLi,

            liHeaderStyles: styles.liHeaderStyles,
        }
        : {
            ulStyles: styles.printUl,
            liStyles: styles.printLi,

            liHeaderStyles: styles.liHeaderStyles,
        }

    console.log(product.specTitle1, product.specTitle2)


    return (

        <>
            <div className={styles.specificationComponentContainer}>
                {
                    product.specTitle1 && product.specList1 &&
                    <div>
                        <span className={styles.printSpecListTitle}>
                            {capitalizeFirstLetterEachWord(product.specTitle1)}
                        </span>
                     
                        <ul className={specListStyles.ulStyles}>

                        
                            {product.specList1.map((e, idx) => (
                                <li className={specListStyles.liStyles} key={idx} >
                                    <PageText type='productPageSpecList'>{e}</PageText>
                                </li>
                            ))}
                        </ul>
                    </div>
                }
                {
                    product.specTitle2 && product.specList2 && (
                        <div>
                            <span className={styles.printSpecListTitle}>
                                {capitalizeFirstLetterEachWord(product.specTitle2)}
                            </span>
                            <ul className={specListStyles.ulStyles}>
                                {product.specList2.map((e, idx) => (
                                    <li className={specListStyles.liStyles} key={idx}>
                                        <PageText type='productPageSpecList'>{e}</PageText>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                {product.specTitle3 && product.specList3 && (
                    <div>
                        <span className={styles.printSpecListTitle}>
                            {capitalizeFirstLetterEachWord(product.specTitle3)}
                        </span>
                        <ul className={specListStyles.ulStyles} >
                            {product.specList3.map((e, idx) => (
                                <li className={specListStyles.liStyles} key={idx}>
                                    <PageText type='productPageSpecList'>{e}</PageText>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
                {product.specTitle4 && product.specList4 && (
                    <div>
                        <span className={styles.printSpecListTitle}>
                            {capitalizeFirstLetterEachWord(product.specTitle4)}
                        </span>
                        <ul className={specListStyles.ulStyles}>
                            {product.specList4.map((e, idx) => (
                                <li className={specListStyles.liStyles} key={idx}>
                                    <PageText type='productPageSpecList'>{e}</PageText>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>
        </>
    );
};