import { useEffect } from 'react';

const useIntersectionObserver = (setActiveSection) => {
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        // Iterate over all the entries
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // If the section is intersecting, set it as the active section
            setActiveSection(entry.target.id);
          }
        });
      },
      {
        threshold: Array.from({ length: 11 }, (_, index) => index / 10), // Multiple thresholds for better tracking
        rootMargin: '0px 0px -50% 0px', // Trigger when the section is 50% visible
      }
    );

    const sections = document.querySelectorAll('[data-section]');
    sections.forEach((section) => {
      observer.observe(section);
    });

    return () => {
      sections.forEach((section) => {
        observer.unobserve(section);
      });
    };
  }, [setActiveSection]);
};

export default useIntersectionObserver;


// import { useEffect } from 'react';


// const useIntersectionObserver = (setActiveSection) => {
 
//   useEffect(() => {
//     const observer = new IntersectionObserver(
//       (entries) => {
//         console.log(entries.isIntersecting)
//         entries.forEach((entry) => {
//           if (entry.isIntersecting) {
//             setActiveSection(entry.target.id);
//           }
//         });
//       },
//       { threshold: 0.5 }
//     );

//     const sections = document.querySelectorAll('[data-section]');
//     console.log(sections)
//     sections.forEach((section) => {
//       observer.observe(section);
//     });

//     return () => {
//       sections.forEach((section) => {
//         observer.unobserve(section);
//       });
//     };
//   }, [setActiveSection]);
// };

// export default useIntersectionObserver;
