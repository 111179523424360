import { PortalCard } from "../../PortalPageComponent/PortalCard/PortalCard";
import { useChartConfig } from "../../../../hooks/chart-config-hook";

import { BarChart } from "../../PortalChartComponent/BarChart";
import { useDataContext } from "../../../../hooks/data-hook";
import { SkeletonComponent } from "../../../Skeletons/SkeletonComponent";
import { NoDataPortalCard } from "../../PortalPageComponent/PortalCard/NoDataPortalCard";
import { useState, useEffect } from 'react';


export const UserByDeviceModelCard = () => {

    const { isDataState } = useDataContext(); // Access filtered data from context
    const data = isDataState.technicalDataFilteredByDate; // Use filtered data

    const singleColor = '#3366CC'; // Define a single color for all columns

    // Define loading and no data states
    const [state, setState] = useState({
        isLoading: true,
        hasData: false,
        showNoDataMessage: false,
    });

    // Reset state when data changes
    useEffect(() => {
        setState({ isLoading: true, hasData: false, showNoDataMessage: false });
    }, [data]);



    const { config: barChartOptions } = useChartConfig(
        'BarChart',
        '',
        'Users',
        '',
        { minValue: 0 },
        [singleColor],
        false
    );


    // Aggregate data by operating system
    const dataMap = new Map();

    data && data.forEach((item) => {
        const target = item.mobileDeviceModel || 'Unknown';
        const count = Number(item.totalUsers);

        if (!dataMap.has(target)) {
            dataMap.set(target, { target, totalUsers: 0 });
        }

        const targetedData = dataMap.get(target);
        targetedData.totalUsers += count;
    });

    // Sort the aggregated data in descending order by totalUsers
    const aggregatedData = Array.from(dataMap.values()).sort((a, b) => b.totalUsers - a.totalUsers);

    // Format the aggregated data for chart rendering
    const chartData = [
        ['Device Model', 'Users'], // Column headers for the chart
        ...aggregatedData.map(item => [item.target, item.totalUsers]) // Map OS and user count
    ];

    // Handle loading and no data logic
    useEffect(() => {
        if (data && data.length > 0) {
            setState({ isLoading: false, hasData: true, showNoDataMessage: false });
        } else {
            const timer = setTimeout(() => {
                setState(prevState => ({ ...prevState, isLoading: false, showNoDataMessage: true }));
            }, 2000);

            return () => clearTimeout(timer); // Cleanup timer on unmount
        }
    }, [data]);

    // While loading, show skeleton component
    if (state.isLoading) {
        return <SkeletonComponent height="33rem" count={1} />;
    }

    // If loading is finished and no data is found, show a "No Data" message
    if (!state.hasData && state.showNoDataMessage) {
        return <NoDataPortalCard dataTitle="Users by Device Model" />;
    }



    return (
        <PortalCard
            cardTitle="User Distribution by Device Model"
            toolTipText="This chart displays the number of users segmented by their mobile device models, such as 'iPhone X', 'Samsung Galaxy S21', or other device types. Understanding which devices are most common helps optimize your application for those devices."
        >
            <BarChart data={chartData} options={barChartOptions} />

        </PortalCard>
    );
};