

import { IconComponent } from '../../Icon/IconComponent';
import { PageText } from '../../Text/Text';
import styles from './NoDataPortalTable.module.css';


export const NoDataPortalTable = ({ dataTitle }) =>

    <div className={styles.noDataTableContainer} >
        <div className={styles.noDataIconWrapper}>
        <div className={styles.noDataIcon1}>
                <IconComponent iconType='portalNoDataBubble' />
            </div>
            <div className={styles.noDataIcon2}>
                <IconComponent iconType='portalNoData' />
            </div>
        </div>
        <div className={styles.noDataTextWrapper}>
            <div className={styles.noDataTitleWrapper}>
                <div className={styles.noDataTitle}>
                    <PageText type='bodySubtitle'> No Data</PageText>
                </div>

            </div>
            <div className={styles.noDataTitleWrapper}>
                <div className={styles.noDataTitle}>
                    <PageText type='bodySubtitle'> {dataTitle} </PageText>
                </div>

            </div>
        </div>



    </div>
