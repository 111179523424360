import { useState, useCallback, useRef, useEffect } from "react";
import { useNotificationHook } from "./use-notification-hooks";
import { useRoutingHook } from "./use-routing-hooks";
import { useNavigate } from "react-router";

export const useHttpClient = () => {
  const [error, setError] = useState();
  const activeHttpRequests = useRef([]);
  const { isModal, setIsModal } = useNotificationHook();
  const { isRoutingState, setIsRoutingState } = useRoutingHook()

  const redirect = useNavigate();

  const handleConfirmError = () => {
    setIsModal({ show: false })
    redirect('/')
  }

  const sendRequest = useCallback(async (url, method = 'GET', body = null, headers = {}) => {

    setIsRoutingState(prevState => ({ ...prevState, isLoading: true }))

    const httpAbortCtrl = new AbortController();
    activeHttpRequests.current.push(httpAbortCtrl);

    try {
      const response = await fetch(url, {
        method, // method:method,
        body,// body:body,
        headers,// headers:headers,
        signal: httpAbortCtrl.signal
      });
      const responseStatusCode = response.status
      const responseData = await response.json();

  
      if (!response.ok) {
        throw new Error(responseData.message);
      }

      // setIsLoading(false);
      setIsRoutingState(prevState => ({ ...prevState, isLoading: false }))
      // return responseData;
      return {
        responseData,
        responseStatusCode
      }
    } catch (err) {

      setError(err.message);

      console.log('Error', err)
      setIsModal({

        modalType: 'errorModal',

        show: true,
        title: "Error",
        errorList: isModal.errorList,
        message: "Something went wrong!",
        onConfirm: handleConfirmError,
        confirmText: "Close",
        // onConfirm: () => setIsModal({ show: false }),

      })
      // setIsModal(prevState => ({
      //   ...prevState,
      //   modalType: 'errorModal',

      //   show: true,
      //   title: "Error",
      //   errorList: isModal.errorList,
      //   message: "Something went wrong!",
      //   onConfirm: handleConfirmError,
      //   confirmText: "Close",
      //   // onConfirm: () => setIsModal({ show: false }),

      // }))


      setIsRoutingState(prevState => ({ ...prevState, isLoading: false }))
      console.error('Error from hook:', err.message);
      throw err;
    }

  }, []);

  const clearError = () => {
    setError(null);
  };

  useEffect(() => {
    return () => {
      activeHttpRequests.current.forEach(abortCtrl => abortCtrl.abort())
    };
  }, []);

  // return { isLoading, error, sendRequest, clearError };
  return { error, sendRequest, clearError };
}


