

import { ForgotPasswordForm } from '../../../components/AuthComponent/ForgotPassword/ForgotPasswordForm';

import { AuthTemplate } from '../../../layout/Auth/AuthTemplate';


const ForgotPasswordPage = () => {

    return (
        <AuthTemplate
            formTitle="Forgot your Password?"
            formSubtitle="Get a password reset link"
            form={<ForgotPasswordForm />}
            footerCTAText="Not a member?"
            footerCTALink="../sign-up"
            footerCTALinkText="Sign up today."
        />
 
    );
}


export default ForgotPasswordPage;