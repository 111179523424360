import { PortalCard } from "../../PortalPageComponent/PortalCard/PortalCard";
import { useChartConfig } from "../../../../hooks/chart-config-hook";


import { BarChart } from "../../PortalChartComponent/BarChart";
import { useDataContext } from "../../../../hooks/data-hook";
import { SkeletonComponent } from "../../../Skeletons/SkeletonComponent";
import { NoDataPortalCard } from "../../PortalPageComponent/PortalCard/NoDataPortalCard";
import { useState, useEffect } from 'react';

export const UserByOSCard = () => {
    const { isDataState } = useDataContext(); // Access filtered data from context
    const data = isDataState.technicalDataFilteredByDate; 

        // Define loading and no data states
        const [state, setState] = useState({
            isLoading: true,
            hasData: false,
            showNoDataMessage: false,
        });

         // Reset state when data changes
    useEffect(() => {
        setState({ isLoading: true, hasData: false, showNoDataMessage: false });
    }, [data]);

    // Handle loading and no data logic
    useEffect(() => {
        if (data && data.length > 0) {
            setState({ isLoading: false, hasData: true, showNoDataMessage: false });
        } else {
            const timer = setTimeout(() => {
                setState(prevState => ({ ...prevState, isLoading: false, showNoDataMessage: true }));
            }, 2000);

            return () => clearTimeout(timer); // Cleanup timer on unmount
        }
    }, [data]);



    const { config: barChartOptions } = useChartConfig(
        'BarChart',
        '',
        'Users',
        '',
        { minValue: 0 },
        ['#3366CC'],
        false, // Show legend
        true, // Show X-axis labels
        true,
    );



    // Aggregate data by operating system
    const dataMap = new Map();

   data && data.forEach((item) => {
        const target = item.operatingSystem || 'Unknown';
        const count = Number(item.totalUsers);

        if (!dataMap.has(target)) {
            dataMap.set(target, { target, totalUsers: 0 });
        }

        const targetedData = dataMap.get(target);
        targetedData.totalUsers += count;
    });

    // Sort the aggregated data in descending order by totalUsers
    const aggregatedData = Array.from(dataMap.values()).sort((a, b) => b.totalUsers - a.totalUsers);

    // Format the aggregated data for chart rendering
    const chartData = [
        ['Operating System', 'Users'], // Column headers for the chart
        ...aggregatedData.map(item => [item.target, item.totalUsers]) // Map OS and user count
    ];

          // While loading, show skeleton component
          if (state.isLoading) {
            return <SkeletonComponent height="33rem" count={1} />;
        }
    
        // If loading is finished and no data is found, show a "No Data" message
        if (!state.hasData && state.showNoDataMessage) {
            return <NoDataPortalCard dataTitle="Users by Operating System" />;
        }
    
    
    return (
        <PortalCard
            cardTitle="User Distribution by Operating System"
            toolTipText="This chart shows the number of users categorized by the operating systems they use, including both desktop and mobile platforms like Windows, macOS, iOS, and Android. Understanding user OS distribution helps tailor your app's experience across these platforms."
          
       >  <BarChart data={chartData} options={barChartOptions} />
           
        </PortalCard>
    );
};