import { createContext, useEffect, useReducer, useMemo } from "react";
import { useRetailer } from "../hooks/use-routing-hooks";

export const ProductContext = createContext({
    publicProducts: [],
    allProducts: [],
    setPublicProducts: () => {},
    fetchProducts: () => {},
    isLoading: false,
    error: null,
});

export const PRODUCT_ACTION_TYPES = {
    SET_PRODUCTS: 'SET_PRODUCTS',       // Consolidated action for setting both public and all products
    SET_LOADING: 'SET_LOADING',
    SET_ERROR: 'SET_ERROR',
};

const productReducer = (state, action) => {
    const { type, payload } = action;

    switch (type) {
        case PRODUCT_ACTION_TYPES.SET_PRODUCTS:
            return {
                ...state,
                publicProducts: payload.filteredProducts,
                allProducts: payload.allProducts,
                isLoading: false,
                error: null,
            };
        case PRODUCT_ACTION_TYPES.SET_LOADING:
            return { ...state, isLoading: true, error: null };
        case PRODUCT_ACTION_TYPES.SET_ERROR:
            return { ...state, isLoading: false, error: payload };
        default:
            throw new Error(`Unhandled type ${type} in productReducer`);
    }
};

const INITIAL_PUBLIC_PRODUCT_STATE = {
    publicProducts: [],
    allProducts: [],
    isLoading: false,
    error: null,
};

export const ProductProvider = ({ children }) => {
    const [state, dispatch] = useReducer(productReducer, INITIAL_PUBLIC_PRODUCT_STATE);
    const { isHomeDepotApp } = useRetailer();
    const { publicProducts, allProducts, isLoading, error } = state;

    const setProducts = (products) => {
        const filteredProducts = isHomeDepotApp.isHomeDepotActive
            ? products
            : products.filter(product => product.store !== 'hd');

        dispatch({
            type: PRODUCT_ACTION_TYPES.SET_PRODUCTS,
            payload: { filteredProducts, allProducts: products }
        });
    };

    const setLoading = () => dispatch({ type: PRODUCT_ACTION_TYPES.SET_LOADING });
    const setError = (errMsg) => dispatch({ type: PRODUCT_ACTION_TYPES.SET_ERROR, payload: errMsg });

    const fetchProducts = async () => {
        setLoading();
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}products`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setProducts(data.allProducts);
        } catch (err) {
            setError(`Error: ${err.message}`);
        }
    };

    useEffect(() => {
        fetchProducts();
    }, [isHomeDepotApp.isHomeDepotActive]);

    const value = useMemo(() => ({
        publicProducts,
        allProducts,
        setPublicProducts: setProducts,
        fetchProducts,
        isLoading,
        error,
    }), [publicProducts, allProducts, isLoading, error]);

    return (
        <ProductContext.Provider value={value}>
            {children}
        </ProductContext.Provider>
    );
};
