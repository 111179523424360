import { useDataContext } from "../../../../hooks/data-hook";

import { ColumnChart } from "../../PortalChartComponent/ColumnChart";

import { useChartConfig } from "../../../../hooks/chart-config-hook";

import { PortalCard } from "../../PortalPageComponent/PortalCard/PortalCard";
import { categorizePagePath } from "../../../../utils/helper-functions";
import { SkeletonComponent } from "../../../Skeletons/SkeletonComponent";
import { NoDataPortalCard } from "../../PortalPageComponent/PortalCard/NoDataPortalCard";
import { useState, useEffect } from 'react';

export const AddToListProductSearchByLandingPageCategoryBarChart = () => {
    const { isDataState } = useDataContext(); 
    const data = isDataState.searchDataFilteredByDate; // Use filtered search data

        // Define loading and no data states
        const [state, setState] = useState({
            isLoading: true,
            hasData: false,
            showNoDataMessage: false,
        });

         // Reset state when data changes
    useEffect(() => {
        setState({ isLoading: true, hasData: false, showNoDataMessage: false });
    }, [data]);

    const { config: barChartOptions } = useChartConfig( 
        'ColumnChart', 
        '', 
        '',
        'Event Count',
        { minValue: 0 },
        ['#3366CC'],
        false, // Show legend
        true, // Show X-axis labels
        true,
    );

    const targetEvents = ['SEARCHED_PRODUCT_ADDED'];



    // Step 1: Filter relevant events
    const filteredEvents = data ? data.filter(event => targetEvents.includes(event.eventName)): [];

    // Step 2: Aggregate eventCount by pagePath
    const eventPageMap = new Map();

    filteredEvents.forEach(item => {
        const pageCategory = categorizePagePath(item.pagePath);
        const eventCount = Number(item.eventCount);

        if (!eventPageMap.has(pageCategory)) {
            eventPageMap.set(pageCategory, { pageCategory, totalEventCount: 0 });
        }

        const pageData = eventPageMap.get(pageCategory);
        pageData.totalEventCount += eventCount;
    });

    // Step 3: Sort the aggregated data in descending order by totalEventCount
    const sortedPages = Array.from(eventPageMap.values()).sort((a, b) => b.totalEventCount - a.totalEventCount);

    // Step 4: Format the data for BarChart
    const chartData = [
        ['Page Group', 'Event Count'], 
        ...sortedPages.map(item => [item.pageCategory, item.totalEventCount])
    ];

    // Step 5: Calculate total and average event count
    const totalEventCount = sortedPages.reduce((acc, curr) => acc + curr.totalEventCount, 0);
    const avgEventCount = sortedPages.length > 0 ? totalEventCount / sortedPages.length : 0;

    
    // Handle loading and no data logic
    useEffect(() => {
        if (filteredEvents && filteredEvents.length !== 0) {
            setState({ isLoading: false, hasData: true, showNoDataMessage: false });
        } else {
            const timer = setTimeout(() => {
                setState(prevState => ({ ...prevState, isLoading: false, showNoDataMessage: true }));
            }, 2000);

            return () => clearTimeout(timer); // Cleanup timer on unmount
        }
    }, [data]);


      // While loading, show skeleton component
      if (state.isLoading) {
        return <SkeletonComponent height="33rem" count={1} />;
    }

    // If loading is finished and no data is found, show a "No Data" message
    if (!state.hasData && state.showNoDataMessage) {
        return <NoDataPortalCard dataTitle="Product Additions from Search by Page Type" />;
    }


    return (
        <PortalCard 
            cardTitle="Page Types Driving Product Additions from Search"
            toolTipText="This chart breaks down the types of pages where users searched for and added products to their list. Understanding these page categories helps identify the context in which users are most likely to add products, offering insights into their browsing behavior."
            // cardData={totalEventCount}
            cardFooter={`Total events: ${totalEventCount.toLocaleString()}, Avg events per page: ${avgEventCount.toFixed(2)}`}
        >
            <ColumnChart data={chartData} options={barChartOptions} />
            {/* {chartData.length > 1 ? (
                <ColumnChart data={chartData} options={barChartOptions} />
            ) : (
                <div>No chart data available.</div>
            )} */}
        </PortalCard>
    );
};
