import { useDataContext } from "../../../../hooks/data-hook";
import { PortalLeaderBoard } from "../../PortalPageComponent/PortalLeaderBoard/PortalLeaderBoard";
import { PortalLeaderBoardRow } from "../../PortalPageComponent/PortalLeaderBoard/PortalLeaderBoardRow";
import { SkeletonComponent } from "../../../Skeletons/SkeletonComponent";
import { NoDataPortalCard } from "../../PortalPageComponent/PortalCard/NoDataPortalCard";
import { useState, useEffect } from 'react';

export const PrintProductListLeaderBoard = ({ limit = 10 }) => {
    const { isDataState } = useDataContext();
    const data = isDataState.printDataFilteredByDate; // Use filtered print data from context


           // Define loading and no data states
           const [state, setState] = useState({
            isLoading: true,
            hasData: false,
            showNoDataMessage: false,
        });

         // Reset state when data changes
    useEffect(() => {
        setState({ isLoading: true, hasData: false, showNoDataMessage: false });
    }, [data]);


    // Step 1: Filter 'Print_Product_List' events
    const printProductEvents = data && data.filter(event => 
        event.eventName === 'LIST_PRINTED' && 
        event['customEvent:productName'] !== '(not set)'
    );

    const productMap = new Map();

    // Step 2: Aggregate data by product name
  printProductEvents &&  printProductEvents.forEach(item => {
        const productsInList = item['customEvent:productsInList'] || '';
        const products = productsInList.split(',').map(product => product.trim());
    
        products.forEach(product => {
            if (product === '(not set)' || product === '') return;
            if (!productMap.has(product)) {
                productMap.set(product, { productName: product, timesInList: 0 });
            }
            productMap.get(product).timesInList += 1;
        });
    });

    // Step 3: Convert the Map to an array and sort by timesInList
    const sortedProducts = Array.from(productMap.values())
        .sort((a, b) => b.timesInList - a.timesInList)
        .slice(0, limit);

    // Handle loading and no data logic
    useEffect(() => {
        if (printProductEvents && printProductEvents.length !== 0) {
            setState({ isLoading: false, hasData: true, showNoDataMessage: false });
        } else {
            const timer = setTimeout(() => {
                setState(prevState => ({ ...prevState, isLoading: false, showNoDataMessage: true }));
            }, 2000);

            return () => clearTimeout(timer); // Cleanup timer on unmount
        }
    }, [data]);

      // While loading, show skeleton component
      if (state.isLoading) {
        return <SkeletonComponent height="33rem" count={1} />;
    }

    // If loading is finished and no data is found, show a "No Data" message
    if (!state.hasData && state.showNoDataMessage) {
        return <NoDataPortalCard dataTitle="Products with Highest Print Frequency" />;
    }


    return (
        <PortalLeaderBoard title="Products with Highest Print Frequency">
            <ul>
                {sortedProducts.map((product, idx) => (
                    <li key={idx}>
                        <PortalLeaderBoardRow
                            rank={idx + 1}
                            dimension={product.productName}
                            metric={product.timesInList}
                        />
                    </li>
                ))}
            </ul>
        </PortalLeaderBoard>
    );
};
