import { useDataContext } from "../../../../hooks/data-hook";
import { PortalLeaderBoard } from "../../PortalPageComponent/PortalLeaderBoard/PortalLeaderBoard";
import { PortalLeaderBoardRow } from "../../PortalPageComponent/PortalLeaderBoard/PortalLeaderBoardRow";
import { SkeletonComponent } from "../../../Skeletons/SkeletonComponent";
import { NoDataPortalCard } from "../../PortalPageComponent/PortalCard/NoDataPortalCard";
import { useState, useEffect } from 'react';

export const LandingPageViewsByGeoLocationLeaderBoard = ({ limit = 10 }) => {
    const { isDataState } = useDataContext();
    const data = isDataState.isDataFilteredByDate; // Use filtered geolocation data from context

    // Define loading and no data states
    const [state, setState] = useState({
        isLoading: true,
        hasData: false,
        showNoDataMessage: false,
    });

    // Reset state when data changes
    useEffect(() => {
        setState({ isLoading: true, hasData: false, showNoDataMessage: false });
    }, [data]);


    // Handle loading and no data logic
    useEffect(() => {
        if (data && data.length > 0) {
            setState({ isLoading: false, hasData: true, showNoDataMessage: false });
        } else {
            const timer = setTimeout(() => {
                setState(prevState => ({ ...prevState, isLoading: false, showNoDataMessage: true }));
            }, 2000);

            return () => clearTimeout(timer); // Cleanup timer on unmount
        }
    }, [data]);


    const locationMap = new Map();

    if (data && Array.isArray(data)) {
        // Step 1: Aggregate page views by city and country
        data.forEach((item) => {
            const key = `${item.country}-${item.city}`; // Create a unique key for each location
            const pageViews = Number(item.screenPageViews);

            if (!locationMap.has(key)) {
                locationMap.set(key, {
                    country: item.country,
                    city: item.city,
                    totalPageViews: 0,
                });
            }

            const current = locationMap.get(key);
            current.totalPageViews += pageViews; // Aggregate page views by location
        });
    }

    // Step 2: Sort the data by totalPageViews in descending order
    const sortedLocations = Array.from(locationMap.values()).sort((a, b) => b.totalPageViews - a.totalPageViews);

    // Step 3: Limit the number of locations displayed based on the `limit` prop
    const limitedLocations = sortedLocations.slice(0, limit);

    if (state.isLoading) {
        return <SkeletonComponent height="33rem" width="40rem" count={1} />;
    }

    // If loading is finished and no data is found, show a "No Data" message
    if (!state.hasData && state.showNoDataMessage) {
        return <NoDataPortalCard dataTitle="Top Geo Locations by Page Views" />;
    }


    // Step 3: Render the leaderboard
    return (
        <PortalLeaderBoard title={`Page Views by Geo Location`}>
            <ul>
                {limitedLocations.map((location, idx) => (
                    <li key={idx}>
                        <PortalLeaderBoardRow
                            rank={idx + 1}
                            dimension={`${location.country}, ${location.city}`} // Display city and country
                            metric={location.totalPageViews} // Display total page views
                        />
                    </li>
                ))}
            </ul>
        </PortalLeaderBoard>
    );
};
