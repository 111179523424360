import TableBody from "../../TableComponent/TableBody";
import TablePagination from "../../TableComponent/TablePagination";
import { useState, useEffect } from "react";
import styles from './TableComponent.module.css'
import { IconComponent } from "../../Icon/IconComponent";
import { useNotificationHook } from "../../../hooks/use-notification-hooks";
import { NoDataPortalTable } from "./NoDataPortalTable";
import TableSkeleton from "../../Skeletons/TableSkeleton";
import { useAuth, useLogout } from "../../../hooks/use-auth-hooks";

import { useNavigate } from "react-router";


export const AdminUserDirectoryTable = () => {

    const logout = useLogout();
    const redirect = useNavigate();
    const { setIsModal } = useNotificationHook();
    const { token, isAdmin, isSuperAdmin } = useAuth();
    const [isUsers, setIsUsers] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    // Define loading and no data states
    const [state, setState] = useState({
        isLoading: true,
        hasData: false,
        showNoDataMessage: false,
    });

    const fetchUsers = async () => {
        try {
            const response = await fetch(
                `${process.env.REACT_APP_BACKEND_URL}admin-users`,
                // `http://localhost:3000/admin-users`,
                {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            // console.log('data',data)
            setIsUsers(data.users);
        } catch (err) {
            console.log(`error ${err}`)
        }
    };

    useEffect(() => {
        if (!isAdmin || !isSuperAdmin) {
            setIsModal({
                show: true,
                modalType: 'confirmationModal',
                title: "Error",
                message: `Please contact an administrator.`,
                cancelText: "Close",
                onCancel: handleUnAuthorizedAccess,
            })
            return
        }
        fetchUsers();
    }, []);


    /* --------------------------------------------------------------------------------------- */
    /* HANDLE NO AUTH REDIRECTS*/
    /* --------------------------------------------------------------------------------------- */
    const handleUnAuthorizedAccess = () => {
        logout();
        setIsModal({ show: false })
    }
   
    // Reset state when data changes
    useEffect(() => {
        setState({ isLoading: true, hasData: false, showNoDataMessage: false });
    }, [isUsers]);
    // Handle loading and no data logic
    useEffect(() => {
        if (isUsers && isUsers.length > 0) {
            setState({ isLoading: false, hasData: true, showNoDataMessage: false });
        } else {
            const timer = setTimeout(() => {
                setState(prevState => ({ ...prevState, isLoading: false, showNoDataMessage: true }));
            }, 2000);

            return () => clearTimeout(timer); // Cleanup timer on unmount
        }
    }, [isUsers]);

    // While loading, show skeleton component
    if (state.isLoading) {
        // return <h1>Loading</h1>
        return <TableSkeleton count={1} />;
    }

    // If loading is finished and no data is found, show a "No Data" message
    if (!state.hasData && state.showNoDataMessage) {
        return <NoDataPortalTable dataTitle="User Directory" height="100rem" />;
    }


    const tableColumns = [
        { key: 'firstName', title: 'Name' },
        // { key: 'lastName', title: 'Last Name' },
        { key: 'email', title: 'Email' },
        {
            key: 'role',
            title: 'Role',
            render: row => row.role === "superAdmin"
                ? "Super Administrator"
                : row.role === "admin" ? "Administrator"
                    : row.role === "user" ? "User" : "Role Not Set"
        },
        // { key: 'role', title: 'Role' },
        // { key: 'status', title: 'Status' },
        { key: 'productsCreated', title: 'Products Created' },
        { key: 'productsUpdated', title: 'Products Updated' },
        { key: 'productsDeleted', title: 'Products Deleted' },
        { key: 'usersCreated', title: 'Users Created' },
        { key: 'usersUpdated', title: 'Users Updated' },
        { key: 'usersDeleted', title: 'Users Deleted' },
        {
            key: 'manage',
            title: 'Manage',
            render: row => (
                <div className={styles.actionIconContainer}>
                    <IconComponent onClick={() => redirect(`/portal/edit-user/${row.userId}`)} iconType='edit' />
                </div>
            )
        }
    ];


    ;

    return (
        isUsers &&
        <>
            <div className={styles.mainTableComponent}>
                <div style={{
                    overflowX: 'auto',
                }}>
                    <div>
                        <TableBody
                            columns={tableColumns}
                            data={isUsers.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)}
                        />
                        <TablePagination
                            itemsPerPage={itemsPerPage}
                            tableData={isUsers}
                            setCurrentPage={setCurrentPage}
                            currentPage={currentPage}
                        />
                    </div>
                </div>
            </div>
        </>
    )



}