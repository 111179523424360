import Loader from "../../components/Loader/Loader";
import Modal from "../../components/Modal/Modal";
import ProductGuideAlerts from "../../components/Alert/Alert";
import { useNotificationHook } from "../../hooks/use-notification-hooks";
import { useRoutingHook } from "../../hooks/use-routing-hooks";

import { Outlet } from "react-router";

export const AuthLayout = () => {
    const { isAlert, setIsAlert, isModal } = useNotificationHook();
    const { isRoutingState } = useRoutingHook();
    return (
        <>
            {isRoutingState.isLoading && <Loader />}

            {isAlert.show && (
                <ProductGuideAlerts
                    onClick={() => setIsAlert({ ...isAlert, show: false })}
                    show={isAlert.show}
                    alertMessage={isAlert.message}
                    type={isAlert.type}
                />
            )}
            {isModal.show &&
                <Modal
                    modalType={isModal.modalType}
                    errorList={isModal.errorList}
                    show={isModal.show}
                    title={isModal.title}
                    message={isModal.message}
                    confirmText={isModal.confirmText}
                    cancelText={isModal.cancelText}
                    onConfirm={isModal.onConfirm}
                    onCancel={isModal.onCancel}
                />

            }
            <Outlet />
        </>
    );
}