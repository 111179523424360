import { motion, useInView } from 'framer-motion';
import styles from './Specifications.module.css';
import { PageText } from '../../Text/Text';
import { capitalizeFirstLetterEachWord } from '../../../utils/helper-functions';
import { useRef } from 'react';
import { AnimatedComponent } from '../../../hooks/use-framer-motion';


// const containerVariants = {
//     hidden: { opacity: 0 },
//     visible: {
//         opacity: 1,
//         transition: {
//             staggerChildren: 0.2,
//             delayChildren: 0.1,
//             duration:.01
//         }
//     }
// };

// const itemVariants = {
//     hidden: { opacity: 0, y: 20 },
//     visible: {
//         opacity: 1,
//         y: 0,
//         transition: {
//             duration: 0.1 // Faster animation for each list item
//         }
//     }
// };
const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
        opacity: 1,
        transition: {
            staggerChildren: 0.05, // Reduced stagger for faster child appearance
            delayChildren: 0.05   // Reduced delay for children to appear sooner
        }
    }
};

const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            duration: 0.2, // Speed up each item’s appearance
        }
    }
};
export const Specifications = ({ product, print = false }) => {



    const specListStyles = print === true
        ? {
            ulStyles: styles.printUl,
            liStyles: styles.printLi,

            liHeaderStyles: styles.liHeaderStyles,
        }
        : {
            ulStyles: styles.specListWrapper,
            liStyles: styles.specListItem,
            liHeaderStyles: styles.liHeaderStyles,
        }

        const deactivateAnimation = print === true 
        ? undefined 
        : "hidden"

    // Create refs and useInView for each title and list section
    const titleRef1 = useRef(null);
    const listRef1 = useRef(null);
    const titleInView1 = useInView(titleRef1, { once: false, threshold: 0.5 });
    const listInView1 = useInView(listRef1, { once: false, threshold: 0.5 });

    const titleRef2 = useRef(null);
    const listRef2 = useRef(null);
    const titleInView2 = useInView(titleRef2, { once: false, threshold: 0.5 });
    const listInView2 = useInView(listRef2, { once: false, threshold: 0.5 });

    const titleRef3 = useRef(null);
    const listRef3 = useRef(null);
    const titleInView3 = useInView(titleRef3, { once: false, threshold: 0.5 });
    const listInView3 = useInView(listRef3, { once: false, threshold: 0.5 });

    const titleRef4 = useRef(null);
    const listRef4 = useRef(null);
    const titleInView4 = useInView(titleRef4, { once: false, threshold: 0.5 });
    const listInView4 = useInView(listRef4, { once: false, threshold: 0.5 });


    return (

        <>
            <div className={styles.specificationComponentContainer}>
                {
                    product.specTitle1 && product.specList1 !== 0 &&
                    <div>
                        <span className={styles.printSpecListTitle}>{capitalizeFirstLetterEachWord(product.specTitle1)}</span>
                        <div className={styles.specListTitle} ref={titleRef1}>
                            {titleInView1 && (
                                <AnimatedComponent
                                    type="wipeEffect"
                                    directionStart="left"
                                    delay={0.1}
                                >
                                    <PageText type='productPageSpecTitle'>
                                        {capitalizeFirstLetterEachWord(product.specTitle1)}
                                    </PageText>
                                </AnimatedComponent>
                            )}
                        </div>
                        <motion.ul
                            className={specListStyles.ulStyles}
                            ref={listRef1}
                            variants={containerVariants}
                            initial={deactivateAnimation}
                            animate={listInView1 ? "visible" : "hidden"}
                        >
                            {product.specList1.map((e, idx) => (
                                <motion.li
                                    className={specListStyles.liStyles}
                                    key={idx}
                                    variants={itemVariants}
                                >
                                    <PageText type='productPageSpecList'>{e}</PageText>
                                </motion.li>
                            ))}
                        </motion.ul>
                    </div>
                }
                {product.specTitle2 && product.specList2 && (
                    <div>
                        <div className={styles.specListTitle} ref={titleRef2}>
                            {titleInView2 && (
                                <AnimatedComponent
                                    type="wipeEffect"
                                    directionStart="left"
                                    delay={0.1}
                                >
                                    <PageText type='productPageSpecTitle'>
                                        {capitalizeFirstLetterEachWord(product.specTitle2)}
                                    </PageText>
                                </AnimatedComponent>
                            )}
                        </div>
                        <motion.ul
                            className={specListStyles.ulStyles}
                            ref={listRef2}
                            variants={containerVariants}
                            initial="hidden"
                            animate={listInView2 ? "visible" : "hidden"}
                        >
                            {product.specList2.map((e, idx) => (
                                <motion.li
                                    className={specListStyles.liStyles}
                                    key={idx}
                                    variants={itemVariants}
                                >
                                    <PageText type='productPageSpecList'>{e}</PageText>
                                </motion.li>
                            ))}
                        </motion.ul>
                    </div>
                )}
                 {product.specTitle3 && product.specList3 && (
                <div>
                    <div className={styles.specListTitle} ref={titleRef3}>
                        {titleInView3 && (
                            <AnimatedComponent
                                type="wipeEffect"
                                directionStart="left"
                                delay={0.1}
                            >
                                <PageText type='productPageSpecTitle'>
                                    {capitalizeFirstLetterEachWord(product.specTitle3)}
                                </PageText>
                            </AnimatedComponent>
                        )}
                    </div>
                    <motion.ul
                        className={specListStyles.ulStyles}
                        ref={listRef3}
                        variants={containerVariants}
                        initial="hidden"
                        animate={listInView3 ? "visible" : "hidden"}
                    >
                        {product.specList3.map((e, idx) => (
                            <motion.li
                                className={specListStyles.liStyles}
                                key={idx}
                                variants={itemVariants}
                            >
                                <PageText type='productPageSpecList'>{e}</PageText>
                            </motion.li>
                        ))}
                    </motion.ul>
                </div>
            )}
                {product.specTitle4 && product.specList4 && (
                    <div>
                        <div className={styles.specListTitle} ref={titleRef4}>
                            {titleInView4 && (
                                <AnimatedComponent
                                    type="wipeEffect"
                                    directionStart="left"
                                    delay={0.1}
                                >
                                    <PageText type='productPageSpecTitle'>
                                        {capitalizeFirstLetterEachWord(product.specTitle4)}
                                    </PageText>
                                </AnimatedComponent>
                            )}
                        </div>
                        <motion.ul
                            className={specListStyles.ulStyles}
                            ref={listRef4}
                            variants={containerVariants}
                            initial="hidden"
                            animate={listInView4 ? "visible" : "hidden"}
                        >
                            {product.specList4.map((e, idx) => (
                                <motion.li
                                    className={specListStyles.liStyles}
                                    key={idx}
                                    variants={itemVariants}
                                >
                                    <PageText type='productPageSpecList'>{e}</PageText>
                                </motion.li>
                            ))}
                        </motion.ul>
                    </div>
                )}
            </div>
        </>
    );
};