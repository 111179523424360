import styles from './AuthTemplate.module.css';
import { homeLink } from '../../utils/helper-functions';
import { ExternalLink } from '../../components/Links/ExternalLink';
import { LinkComponent } from '../../components/Links/LinkComponent';
import { GridSystem } from '../../components/GridSystem/GridSystem';
import { AnimatedComponent, AnimatedImage } from '../../hooks/use-framer-motion';
import { PageText } from '../../components/Text/Text';

export const AuthTemplate = ({ formTitle, formSubtitle, form, footerCTAText, footerCTALink, footerCTALinkText, children }) => {

    return (
        <div className={styles.mainContainer}>
            <div className={styles.body}>
                <div className={styles.logo}>
                    <LinkComponent href='/'>
                        <AnimatedImage
                            type="bounceEffect" delay={.3}
                            src={`/assets/image/logos/lg-logo.webp`}
                            alt='LG Logo in heritage red and gray'
                        />
                    </LinkComponent>
                </div>
                <div className={styles.headerText}>
                    <AnimatedComponent type="wipeEffect" directionStart='left' delay={0.1}>
                        <PageText type='formHeaderTitle' >{formTitle}</PageText>
                    </AnimatedComponent>
                    {
                        formSubtitle &&
                        <AnimatedComponent type="wipeEffect" directionStart='left' delay={0.1}>
                            <PageText type='bodySubtitle' >{formSubtitle}</PageText>
                        </AnimatedComponent>
                    }
                </div>
                <div>
                    {children}
                </div>
                <div className={styles.form}>
                    {form}
                </div>
                {footerCTAText &&
                    <div className={styles.footerCTAWrapper}>
                        <AnimatedComponent type='3dRoationDropdownEffects' delay={.3}>
                            <PageText type='bodyDescription'> {footerCTAText}</PageText>
                            {"  "}
                            <span className={styles.linkText}>
                                <LinkComponent href={footerCTALink} >
                                    <PageText type='bodyDescription'> {footerCTALinkText}</PageText>
                                </LinkComponent>
                            </span>
                        </AnimatedComponent>
                    </div>

                }
            </div>
            <div className={styles.bodyBreak}></div>
            <div className={styles.footer}>
                <GridSystem>
                    <div className={styles.footerLinks}>
                        <ExternalLink href={'https://www.lg.com/us/legal'} ><PageText type='footerStatement'>Terms</PageText></ExternalLink>
                        <ExternalLink href={'https://privacy.us.lg.com/policies'} ><PageText type='footerStatement'>Privacy</PageText></ExternalLink>
                        <LinkComponent href={homeLink}><PageText type='footerStatement'>Homepage</PageText></LinkComponent>
                    </div>
                </GridSystem>
            </div>
        </div>
    );
}