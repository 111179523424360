import { LoginForm } from '../../../components/AuthComponent/Login/LoginForm';
import { AuthTemplate } from '../../../layout/Auth/AuthTemplate';

const LoginPage = () => {

    return (
        <AuthTemplate
        formTitle="LG Product Guide"
        formSubtitle="Sign in to your account"
        form={  <LoginForm />}
        footerCTAText="Forgot your password?"
        footerCTALink="../forgot-password"
         footerCTALinkText="Request a password reset link!"
       />   
    );
};

export default LoginPage;
