
import { motion, useInView } from 'framer-motion';
import { AnimatedComponent } from '../../../hooks/use-framer-motion';
import { useRef } from 'react';
import { ExternalLinkButton } from "../../Button/ExternalLinkButton";
import { PageText } from "../../Text/Text";
import styles from './ExternalSpecificationSheet.module.css';
import { logEvent } from "../../../utils/google-analytics";

export const ExternalSpecificationSheetComponent = ({ product }) => {

    const containerRef = useRef(null);
    const isInView = useInView(containerRef, { once: false, threshold: 0.5 });
    const pageTextRef = useRef(null);
    const pageTextInView = useInView(pageTextRef, { once: false, threshold: 0.5 });


    const validateSpecSheetLink = (specLink) => {
        if (specLink === '' || specLink === null) {
            return null
        } else {
            return specLink;
        }
    }
    const testSpecLinks = validateSpecSheetLink(product.specSheetLink);

    const containerVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                staggerChildren: 0.2, // Delay between each child animation
                delayChildren: 0.1,   // Initial delay before the first child starts animating
            }
        }
    };
    const itemVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: { opacity: 1, y: 0 }
    };

    const handleExternalLinkClick = (url, title) => {
        logEvent('RESOURCE_CLICKED', {
            productName: product.title,
            productCategory: product.category,
            productSubcategory: product.subcategory,
            destinationUrl: url,
            resourceType: title,
        });
    }

    // Add specSheetLink as the first item in the sections array
    const resources = [
        { resourceTitle: `${product.title} Spec Sheet`, resourceUrl: product.specSheetLink },
        ...product.sections,
    ];


    
    return (
        <>
            {
                // product.specSheetLink &&
                (testSpecLinks !== null || (product.sections.length !== 0 && product.sections.some(section => section.hasOwnProperty('resourceQrCodeImage')))) &&
            
                <section className={styles.applianceSectionContainer}>
                    <div className={styles.titleContainer}>
                        <div ref={pageTextRef}>
                            {pageTextInView && (
                                <AnimatedComponent
                                    type="wipeEffect"
                                    directionStart='left'
                                    delay={0.1}
                                >
                                    <PageText type='bodyTitle'>External resources</PageText>
                                </AnimatedComponent>
                            )}


                        </div>

                        
                    </div>
                    <motion.div
                        className={styles.resourceButtonsWrapper}
                        ref={containerRef}
                        variants={containerVariants}
                        initial="hidden"
                        animate={isInView ? "visible" : "hidden"}
                    >
                        {resources.map((e, idx) => (
                            <motion.span
                                key={idx}
                                variants={itemVariants}
                                onClick={() => handleExternalLinkClick(e.resourceUrl, e.resourceTitle)}
                            >
                                <ExternalLinkButton
                                    linkText={`${e.resourceTitle}`}
                                    href={e.resourceUrl}
                                />
                            </motion.span>
                        ))}
                    </motion.div>
                </section>
            }

        </>
    );
}