import styles from './UpcCode.module.css';
import { motion, useInView } from 'framer-motion';
import { useRef } from 'react';
import { AnimatedComponent } from '../../../hooks/use-framer-motion';
import { PageText } from '../../Text/Text';

export const UpcCode = ({ upc }) => {

    const containerRef = useRef(null);
    const isInView = useInView(containerRef, { once: false, threshold: 0.5 });
    const pageTextRef = useRef(null);
    const pageTextInView = useInView(pageTextRef, { once: false, threshold: 0.5 });

    const containerVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                staggerChildren: 0.2, // Delay between each child animation
                delayChildren: 0.1,   // Initial delay before the first child starts animating
            }
        }
    };
    const itemVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: { opacity: 1, y: 0 }
    };

    return (
        upc ?
        <section className={styles.applianceSectionContainer}>
                <div className={styles.titleContainer}>
                    <div ref={pageTextRef}>
                    {pageTextInView && (
                             <AnimatedComponent
                             type="wipeEffect"
                             directionStart='left'
                             delay={0.1}
                         >
                             <PageText type='bodyTitle'>UPC Codes</PageText>
                         </AnimatedComponent>
                        )}
                       

                    </div>
                </div>
                <motion.ul className={styles.upcListWrapper}
                    ref={containerRef}
                    variants={containerVariants}
                    initial="hidden"
                    animate={isInView ? "visible" : "hidden"}
                >
                    {upc.map((e, idx) => (
                        <motion.li 
                        variants={itemVariants}
                        className={styles.upcListItem} key={idx}>
                            <PageText type='productPageColorSwatch'>{e}</PageText>
                        </motion.li>
                    ))}
                </motion.ul>
          </section>

            : null


    );
}

