import { AuthTemplate } from '../../../layout/Auth/AuthTemplate';
import { CreateNewPasswordForm } from '../../../components/AuthComponent/CreateNewPassword/CreateNewPasswordForm';

const PasswordResetPage = () => {

    return (
        <AuthTemplate
            formTitle="LG Product Guide"
            formSubtitle="Reset your password"
            form={<CreateNewPasswordForm />}
        />
    );
}

export default PasswordResetPage;