import React, { useEffect, useState, useRef } from 'react';
import { motion, animate } from 'framer-motion';
import styles from './ScrollingComponent.module.css';
import { ApplianceCard } from '../../ProductCards/ApplianceCard/ApplianceCard';
import { useScrollableRefs } from '../../../hooks/use-ref-hook';
import { CountProductsInCategory } from '../../../utils/helper-functions';
import { ScrollHeader } from '../Header/ScrollHeader';
import { GridSystem } from '../../GridSystem/GridSystem';
import { ApplianceCategoriesSkeletonComponent } from '../../../pages/Main/Appliance-Categories/ApplianceCategoriesSkeleton';

// Animation variants for staggering the product cards
const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
        opacity: 1,
        transition: {
            staggerChildren: 0.2,
            delayChildren: 0.1,
        }
    }
};

const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
};

export const ScrollingComponent = ({ processedProducts }) => {
    const numberOfSections = processedProducts.flatMap(Object.keys).length;
    const scrollRefs = useScrollableRefs(numberOfSections);
    const [scrollDisabled, setScrollDisabled] = useState({});
    const [scrollProgress, setScrollProgress] = useState({});
    const containerRefs = useRef([]);

    // Initialize containerRefs
    if (containerRefs.current.length !== numberOfSections) {
        containerRefs.current = Array.from({ length: numberOfSections }, () => React.createRef());
    }

    // Set up initial scroll state
    useEffect(() => {
        const initialState = {};
        const initialProgress = {};
        scrollRefs.forEach((_, idx) => {
            initialState[idx] = { left: true, right: false };
            initialProgress[idx] = 0;
        });
        setScrollDisabled(initialState);
        setScrollProgress(initialProgress);

        // Check initial scroll position for each ref
        scrollRefs.forEach((ref, idx) => {
            if (ref.current) {
                updateScrollButtons(ref, idx);
                updateScrollProgress(ref, idx);
            }
        });
    }, [scrollRefs]);

    const updateScrollButtons = (scrollWrapper, idx) => {
        if (scrollWrapper && scrollWrapper.current) {
            const { scrollLeft, scrollWidth, clientWidth } = scrollWrapper.current;
            setScrollDisabled(prevState => ({
                ...prevState,
                [idx]: {
                    left: scrollLeft <= 0,
                    right: scrollLeft + clientWidth >= scrollWidth - 1,
                },
            }));
        }
    };

    const updateScrollProgress = (scrollWrapper, idx) => {
        if (scrollWrapper && scrollWrapper.current) {
            const { scrollLeft, scrollWidth, clientWidth } = scrollWrapper.current;
            const progress = (scrollLeft / (scrollWidth - clientWidth)) * 100;
            setScrollProgress(prevState => ({
                ...prevState,
                [idx]: progress,
            }));
        }
    };

    // const scroll = (scrollWrapper, direction, idx) => {
    //     if (scrollWrapper && scrollWrapper.current) {
    //         const scrollAmount = 300; // Adjust this value as needed
    //         if (direction === 'left') {
    //             scrollWrapper.current.scrollLeft -= scrollAmount;
    //         } else {
    //             scrollWrapper.current.scrollLeft += scrollAmount;
    //         }

    //         // Update button disabled state and scroll progress after scrolling
    //         setTimeout(() => {
    //             updateScrollButtons(scrollWrapper, idx);
    //             updateScrollProgress(scrollWrapper, idx);
    //         }, 100);
    //     }
    // };

//     const scroll = (scrollWrapper, direction, idx) => {
//     if (scrollWrapper && scrollWrapper.current) {
//         const itemWidth = scrollWrapper.current.firstChild?.clientWidth || 300; // Fallback to 300px if item width can't be determined
//         const scrollAmount = itemWidth * 3; // Scroll by the width of three items
//         console.log(itemWidth)
//         if (direction === 'left') {
//             scrollWrapper.current.scrollLeft -= scrollAmount;
//         } else {
//             scrollWrapper.current.scrollLeft += scrollAmount;
//         }

//         // Update button disabled state and scroll progress after scrolling
//         setTimeout(() => {
//             updateScrollButtons(scrollWrapper, idx);
//             updateScrollProgress(scrollWrapper, idx);
//         }, 100);
//     }
// };
// const scroll = (scrollWrapper, direction, idx) => {
//     if (scrollWrapper && scrollWrapper.current) {
//         const itemWidth = scrollWrapper.current.firstChild?.clientWidth || 300;
//         const scrollAmount = itemWidth * 1;
//         // const scrollAmount = itemWidth * 3;
//         const start = scrollWrapper.current.scrollLeft;
//         const end = direction === 'left' ? start - scrollAmount : start + scrollAmount;

//         animate(start, end, {
//             duration: 0.6, // 600ms animation duration
//             ease: [0.25, 0.1, 0.25, 1], // Easing function (cubic-bezier)
//             onUpdate: (value) => {
//                 scrollWrapper.current.scrollLeft = value;
//             }
//         });

//         // Update button disabled state and scroll progress after scrolling
//         setTimeout(() => {
//             updateScrollButtons(scrollWrapper, idx);
//             updateScrollProgress(scrollWrapper, idx);
//         }, 100);
//     }
// };
const scroll = (scrollWrapper, direction, idx) => {
    if (scrollWrapper && scrollWrapper.current) {
        const itemWidth = scrollWrapper.current.firstChild?.clientWidth || 300; // Width of each card
        const gapSizeInRem = 2; // The gap size in rem
        const rootFontSize = parseFloat(getComputedStyle(document.documentElement).fontSize); // Get the root font size in pixels

        const gapSizeInPx = gapSizeInRem * rootFontSize; // Convert gap size from rem to pixels
        const scrollAmount = itemWidth + gapSizeInPx; // Total scroll amount including the gap

        const start = scrollWrapper.current.scrollLeft;
        const end = direction === 'left' ? start - scrollAmount : start + scrollAmount;

        animate(start, end, {
            duration: 0.6, // 600ms animation duration
            ease: [0.25, 0.1, 0.25, 1], // Easing function (cubic-bezier)
            onUpdate: (value) => {
                scrollWrapper.current.scrollLeft = value;
            }
        });

        // Update button disabled state and scroll progress after scrolling
        setTimeout(() => {
            updateScrollButtons(scrollWrapper, idx);
            updateScrollProgress(scrollWrapper, idx);
        }, 100);
    }
};
    let refIdx = 0; // Initialize refIdx outside to keep track of refs
    return (
        <>
            {
                processedProducts.length !== 0 ?
                    processedProducts.map((products, idx) => (
                        <div key={`product-group-${idx}`}>
                            {Object.entries(products).map(([subcategory, items]) => {
                                const currentRefIdx = refIdx++;
                                const containerRef = containerRefs.current[currentRefIdx];

                                return (
                                    <div id={subcategory} className={styles.mainContainer} key={subcategory}>
                                        <GridSystem
                                            gridType='spread'
                                            containerPaddingTop='2rem'
                                            containerBackgroundColor='#E6E1D6'
                                        >
                                            <div className={styles.innerContainer}>
                                                <div className={styles.scrollHeaderWrapper}>
                                                    <div className={styles.scrollHeader}>
                                                        <ScrollHeader
                                                            leftOnClick={() => scroll(scrollRefs[currentRefIdx], 'left', currentRefIdx)}
                                                            rightOnClick={() => scroll(scrollRefs[currentRefIdx], 'right', currentRefIdx)}
                                                            leftDisabled={scrollDisabled[currentRefIdx]?.left}
                                                            rightDisabled={scrollDisabled[currentRefIdx]?.right}
                                                            itemCount={CountProductsInCategory(items)}
                                                            headerText={subcategory}
                                                            progress={scrollProgress[currentRefIdx]}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </GridSystem>
                                        <div className={styles.mainScrollingDivContainer}>
                                            <GridSystem gridType='spread' containerBackgroundColor='#E6E1D6'>
                                                <div className={styles.innerContainer}>
                                                    <div className={styles.scrollWrapper}>
                                                        <motion.div
                                                            className={styles.container}
                                                            ref={scrollRefs[currentRefIdx]}
                                                            onScroll={() => {
                                                                updateScrollButtons(scrollRefs[currentRefIdx], currentRefIdx);
                                                                updateScrollProgress(scrollRefs[currentRefIdx], currentRefIdx);
                                                            }}
                                                            variants={containerVariants}
                                                            initial="hidden"
                                                            animate="visible"
                                                        >
                                                            {items.map((product, itemIdx) => (
                                                                <motion.div
                                                                    key={product.id || itemIdx}
                                                                    variants={itemVariants}
                                                                >
                                                                    <ApplianceCard product={product} />
                                                                </motion.div>
                                                            ))}
                                                        </motion.div>
                                                    </div>
                                                </div>
                                            </GridSystem>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    ))
                    : <ApplianceCategoriesSkeletonComponent />
            }
        </>
    );
};
