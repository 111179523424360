import { PortalCard } from "../../PortalPageComponent/PortalCard/PortalCard";
import { useChartConfig } from "../../../../hooks/chart-config-hook";
import { transformWithSchema } from "../../../../utils/data-transformer";
import { AreaChart } from "../../PortalChartComponent/AreaChart";
import { useDataContext } from "../../../../hooks/data-hook";
import { SkeletonComponent } from "../../../Skeletons/SkeletonComponent";
import { NoDataPortalCard } from "../../PortalPageComponent/PortalCard/NoDataPortalCard";
import { useState, useEffect } from 'react';

export const LandingPageUsersCard = () => {
    const { isDataState } = useDataContext();
    const data = isDataState.isDataFilteredByDate;

    // Define loading and no data states
    const [state, setState] = useState({
        isLoading: true,
        hasData: false,
        showNoDataMessage: false,
    });

    // Reset state when data changes
    useEffect(() => {
        setState({ isLoading: true, hasData: false, showNoDataMessage: false });
    }, [data]);

    // Step 1: Aggregate the data by date
    const aggregatedData = data
        ? data.reduce((acc, curr) => {
            const date = curr.date;
            const totalUsers = Number(curr.totalUsers);

            if (acc[date]) {
                acc[date].totalUsers += totalUsers;
            } else {
                acc[date] = { ...curr, totalUsers };
            }
            return acc;
        }, {})
        : {};

    // Step 2: Convert the aggregated object back into an array
    const aggregatedDataArray = Object.values(aggregatedData);

    // Step 3: Sort the aggregated data by date
    const sortedData = aggregatedDataArray.sort((a, b) => {
        const dateA = new Date(a.date.slice(0, 4), a.date.slice(4, 6) - 1, a.date.slice(6, 8));
        const dateB = new Date(b.date.slice(0, 4), b.date.slice(4, 6) - 1, b.date.slice(6, 8));
        return dateA - dateB;
    });

    // Step 4: Filter valid days where totalUsers is a number
    const validDays = sortedData.filter(day => day.totalUsers && !isNaN(Number(day.totalUsers)));

    // Step 5: Calculate total and average user counts
    const totalUserCount = validDays.reduce((acc, curr) => acc + Number(curr.totalUsers), 0);
    const avgUserCount = validDays.length > 0 ? totalUserCount / validDays.length : 0;

    // Step 6: Dynamically generate the Y-axis ticks
    const minValue = 0;
    const maxValue = Math.ceil(Math.max(...validDays.map(day => Number(day.totalUsers))) / 20) * 20;
    const stepSize = 10;

    const vAxisTicks = [];
    for (let i = minValue; i <= maxValue; i += stepSize) {
        vAxisTicks.push(i);
    }

    // Step 7: Configure the chart options
    const { config: areaChartOptions } = useChartConfig(
        'AreaChart',
        '',
        '', // Horizontal Axis Title
        'Users', // Vertical Axis Title
        { minValue: 0 }, // Y Axis Range
        ['#3366CC'], // Colors
        false, // Show legend
        true, // Show X-axis labels
        true, // Show Y-axis labels
        vAxisTicks // Set the tick intervals for the Y-axis
    );

    let areaChartData = null;
    if (sortedData.length > 0) {
        try {
            areaChartData = transformWithSchema(sortedData, 'pageViewSnapshot');
        } catch (error) {
            console.error(`Error transforming data: ${error.message}`);
        }
    }

    // Handle loading and no data logic
    useEffect(() => {
        if (data && data.length > 0) {
            setState({ isLoading: false, hasData: true, showNoDataMessage: false });
        } else {
            const timer = setTimeout(() => {
                setState(prevState => ({ ...prevState, isLoading: false, showNoDataMessage: true }));
            }, 2000);

            return () => clearTimeout(timer); // Cleanup timer on unmount
        }
    }, [data]);

    // While loading, show skeleton component
    if (state.isLoading) {
        return <SkeletonComponent height="33rem" width="40rem" count={1} />;
    }

    // If loading is finished and no data is found, show a "No Data" message
    if (!state.hasData && state.showNoDataMessage) {
  
        return <NoDataPortalCard dataTitle="Total Users"/>;
    }

    // Render the card if we have data
    return (
        totalUserCount && avgUserCount && areaChartData !== null ? (
            <PortalCard
                cardTitle="Total Users"
                cardData={totalUserCount.toLocaleString()}
                cardFooter={`${parseInt(avgUserCount)} users per day on average`}
                toolTipText="The number of distinct users who have logged at least one event."
            >
                {areaChartData ? (
                    <AreaChart data={areaChartData} options={areaChartOptions} />
                ) : (
                    <div>No chart data available.</div>
                )}
            </PortalCard>
        ) : (
            <SkeletonComponent height="33rem" width="40rem" count={1} />
        )
    );
};
