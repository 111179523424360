import styles from './Homepage.module.css';
import { GridSystem } from "../../../components/GridSystem/GridSystem";
import { PageText } from '../../../components/Text/Text';
import { SearchComponent } from '../../../components/Search/SearchComponent/SearchComponent';
import { useSearchHook } from '../../../hooks/use-search-hooks';
import { AnimatedComponent } from '../../../hooks/use-framer-motion';
import { useEffect, useState } from 'react';
import { HomePageSkeletonComponent } from './HomePageSkeleton';
import { CollarButtons } from '../../../components/FeatureSection/CollarButtons/CollarButtons';
import { Introduction } from '../../../components/FeatureSection/HomeFeatureSection/Introduction';
import { Resource } from '../../../components/FeatureSection/HomeFeatureSection/Resources';


const Homepage = () => {

    const { setIsHomepageSearchState, setIsMobileSearchState } = useSearchHook();

    const [isLoading, setIsLoading] = useState(true);
 
    useEffect(() => {
        // Set a timer for 1 second to handle the loading state
        const timer = setTimeout(() => {
            setIsLoading(false);
        }, 700);

        // Cleanup the timer on component unmount
        return () => clearTimeout(timer);
    }, []);

    const handleHomepageSearchClick = () => {
        setIsHomepageSearchState(prevState => ({ ...prevState, isHomepageSearch: true }))
    }

    const handleHomepageMobileSearchClick = () => {
        setIsMobileSearchState(prevState => ({ ...prevState, isMobileSearch: true }))
    }

    if (isLoading) {
        return <HomePageSkeletonComponent />;
    }

    return (
        <>
            <GridSystem gridType='spread' containerBackgroundColor="#F0ECE4" >
                <div className={styles.heroContainer}>
                    <div className={styles.contentWrapper}>
                        <div className={styles.heroWrapper}>
                            <div className={styles.heroHeader}>
                                <div className={styles.title}>
                                    <AnimatedComponent type="wipeEffect" directionStart='left' delay={0.5}>
                                        <PageText type='heroSubtitle'>LG Product Guide</PageText>
                                    </AnimatedComponent>
                                </div>
                                <div className={styles.title}>
                                    <AnimatedComponent type="wipeEffect" directionStart='left' delay={1}>
                                        <PageText type='heroTitle'>Explore home appliances</PageText>
                                    </AnimatedComponent>
                                </div>
                            </div>
                            <div className={styles.heroSearch}>
                                <div className={styles.desktopHomeSearchWrapper}>
                                    <div onClick={handleHomepageSearchClick} className={styles.desktopHomeSearchInputWrapper}>
                                        <SearchComponent type='homepage' />
                                    </div>
                                </div>
                                <div className={styles.mobileHomeSearchWrapper}>
                                    <div onClick={handleHomepageMobileSearchClick} className={styles.mobileHomeSearchInputWrapper}>
                                        <SearchComponent type='homepage' />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className={styles.footer}>

                                    <AnimatedComponent type="wipeEffect" directionStart='left' delay={1}>
                                        <PageText type='heroDescription'>Discover best-in-class products and accessories with the LG Product Guide.</PageText>
                                    </AnimatedComponent>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </GridSystem>
            <CollarButtons />
            <Introduction />
            <Resource/>
        </>
    )
}

export default Homepage;