import styles from './VideoComponent.module.css';
import { motion, useInView } from 'framer-motion';

import { GenericScrollHeader } from "../../ScrollingComponent/Header/GenericScrollHeader";
import { useState, useEffect, useRef } from 'react';
import { GridSystem } from '../../GridSystem/GridSystem';
import { useResponsiveStateHook } from '../../../hooks/responsive-hook';
import { PageText } from '../../Text/Text';
import { AnimatedComponent } from '../../../hooks/use-framer-motion';


const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
        opacity: 1,
        transition: {
            staggerChildren: 0.2, // Delay between each child animation
            delayChildren: 0.1,   // Initial delay before the first child starts animating
        }
    }
};

const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
};



const MobileVideoComponent = ({ videos }) => {

    const [scrollDisabled, setScrollDisabled] = useState({});
    const scrollRef = useRef();
    const containerRef = useRef(null);
    const isInView = useInView(containerRef, { once: false, threshold: 0.5 });
    const pageTextRef = useRef(null);
    const pageTextInView = useInView(pageTextRef, { once: false, threshold: 0.5 });

    useEffect(() => {
        const initialState = { left: true, right: false };
        setScrollDisabled(initialState);
        if (scrollRef.current) {
            updateScrollButtons(scrollRef);
        }
    }, [scrollRef]);

    const updateScrollButtons = (scrollWrapper) => {
        if (scrollWrapper && scrollWrapper.current) {
            const { scrollLeft, scrollWidth, clientWidth } = scrollWrapper.current;
            setScrollDisabled({
                left: scrollLeft <= 0,
                right: scrollLeft + clientWidth >= scrollWidth - 1,
            });
        }
    };

    const scroll = (direction) => {
        if (scrollRef.current) {
            const scrollAmount = 300; // Adjust this value as needed
            if (direction === 'left') {
                scrollRef.current.scrollLeft -= scrollAmount;
            } else {
                scrollRef.current.scrollLeft += scrollAmount;
            }
            // Update button disabled state after scrolling
            setTimeout(() => {
                updateScrollButtons(scrollRef);
            }, 100);
        }
    };

    const featureVideos = (list) => {
        return list && list.map((videos, idx) => {
            const videoId = videos.split('/').pop();
            return (
                <motion.div
                    key={idx}
                    // className={styles.videoContainer}
                    variants={itemVariants}
                >
                    <iframe
                        loading='eager'
                        title={`video-${idx}`}
                        width="300"
                        height="170"
                        src={`https://www.youtube.com/embed/${videoId}`}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                </motion.div>
            );
        })
    }

    return (

        <GridSystem
            containerPaddingTop='2rem'
            containerPaddingBottom='2rem'
            containerBorderBottom='1px solid #D0CBC1'
            containerBackgroundColor='#E6E1D6'
        >
            <section className={styles.applianceSectionContainer}>
                <div className={styles.sectionTitle}>
                    <div ref={pageTextRef}>
                        {pageTextInView && (
                            <AnimatedComponent
                                type="wipeEffect"
                                directionStart='left'
                                delay={0.1}
                            >
                                <PageText type='bodyTitle'>Feature innovation</PageText>
                            </AnimatedComponent>
                        )}
                    </div>
                </div>
                {/* <div className={styles.videosHeaderWrapper}> */}
                <div className={styles.videosScrollHeader}>
                    <GenericScrollHeader
                        // headerText='Feature Innovation'
                        leftOnClick={() => scroll('left')}
                        rightOnClick={() => scroll('right')}
                        leftDisabled={scrollDisabled.left}
                        rightDisabled={scrollDisabled.right}
                    />
                </div>
                {/* </div> */}

                <motion.div
                    className={styles.scrollWrapper}
                    ref={containerRef}
                    variants={containerVariants}
                    initial="hidden"
                    animate={isInView ? "visible" : "hidden"}
                >
                    <div ref={scrollRef} className={styles.container}>
                        {featureVideos(videos)}
                    </div>
                </motion.div>
            </section>
        </GridSystem>

    );
}

const DesktopVideoComponent = ({ videos }) => {
    const [scrollDisabled, setScrollDisabled] = useState({});
    const scrollRef = useRef();
    const containerRef = useRef(null);
    const isInView = useInView(containerRef, { once: false, threshold: 0.5 });
    const pageTextRef = useRef(null);
    const pageTextInView = useInView(pageTextRef, { once: false, threshold: 0.5 });


    useEffect(() => {
        const initialState = { left: true, right: false };
        setScrollDisabled(initialState);
        if (scrollRef.current) {
            updateScrollButtons(scrollRef);
        }
    }, [scrollRef]);

    const updateScrollButtons = (scrollWrapper) => {
        if (scrollWrapper && scrollWrapper.current) {
            const { scrollLeft, scrollWidth, clientWidth } = scrollWrapper.current;
            setScrollDisabled({
                left: scrollLeft <= 0,
                right: scrollLeft + clientWidth >= scrollWidth - 1,
            });
        }
    };

    const scroll = (direction) => {
        if (scrollRef.current) {
            const scrollAmount = 300; // Adjust this value as needed
            if (direction === 'left') {
                scrollRef.current.scrollLeft -= scrollAmount;
            } else {
                scrollRef.current.scrollLeft += scrollAmount;
            }
            // Update button disabled state after scrolling
            setTimeout(() => {
                updateScrollButtons(scrollRef);
            }, 100);
        }
    };

    const featureVideos = (list) => {
        return list && list.map((videos, idx) => {
            const videoId = videos.split('/').pop();
            return (
                <motion.div
                    key={idx}
                    // className={styles.videoContainer}
                    variants={itemVariants}
                >
                    <iframe
                        loading='eager'
                        title={`video-${idx}`}
                        width="600"
                        height="340"
                        src={`https://www.youtube.com/embed/${videoId}`}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                </motion.div>
            );
        })
    }

    return (
        <GridSystem
             containerPaddingTop='4rem'
                    containerPaddingBottom='4rem'
            containerBorderBottom='1px solid #D0CBC1'
            containerBackgroundColor='#E6E1D6'
        >
            <section className={styles.applianceSectionContainer}>
                <div ref={pageTextRef}>
                    {pageTextInView && (
                        <AnimatedComponent
                            type="wipeEffect"
                            directionStart='left'
                            delay={0.1}
                        >
                            <PageText type='bodyTitle'>Feature innovation</PageText>
                        </AnimatedComponent>
                    )}
                </div>

                <div className={styles.videosScrollHeader}>
                    <GenericScrollHeader
                        // headerText='Feature Innovation'
                        leftOnClick={() => scroll('left')}
                        rightOnClick={() => scroll('right')}
                        leftDisabled={scrollDisabled.left}
                        rightDisabled={scrollDisabled.right}
                    />
                </div>

                <motion.div
                    className={styles.scrollWrapper}
                    ref={containerRef}
                    variants={containerVariants}
                    initial="hidden"
                    animate={isInView ? "visible" : "hidden"}
                >
                    <div ref={scrollRef} className={styles.container}>
                        {featureVideos(videos)}
                    </div>
                </motion.div>
            </section>
        </GridSystem >
    );
}

export const VideoComponent = ({ videos }) => {

    const { isDesktop, isMobile } = useResponsiveStateHook()
    return (
        <>
            {isDesktop && <DesktopVideoComponent videos={videos} />}
            {isMobile && <MobileVideoComponent videos={videos} />}
        </>

    );
} 