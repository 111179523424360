import { useRef } from "react";
import { PageText } from "../../Text/Text";
import styles from "./Introduction.module.css";
import { motion, useInView } from "framer-motion";
import { LinkComponent } from "../../Links/LinkComponent";
import { AnimatedCheckmark } from "../../AnimatedCheckmark/AnimatedCheckmark";
import { GridSystem } from "../../GridSystem/GridSystem";
import { AnimatedComponent, AnimatedImage } from "../../../hooks/use-framer-motion";
import { BenefitCards } from "../BenefitCards/BenefitCards";
import { useResponsiveStateHook } from "../../../hooks/responsive-hook";

export const Introduction = ({

}) => {

    const {isMobile } = useResponsiveStateHook();

    const sectionTitleRef0 = useRef(null);
    const sectionTitleInView0 = useInView(sectionTitleRef0, { once: true, threshold: 0.5 });
    const sectionTitleRef = useRef(null);
    const sectionTitleInView = useInView(sectionTitleRef, { once: true, threshold: 0.5 });

    const introImageRef1 = useRef(null);
    const introImageInView1 = useInView(introImageRef1, { once: true, threshold: 0.5 });

    const introImageRef2 = useRef(null);
    const introImageInView2 = useInView(introImageRef2, { once: true, threshold: 0.2 });


    const sectionDescriptionRef = useRef(null);
    const sectionDescriptionInView = useInView(sectionDescriptionRef, { once: true, threshold: 0.5 });

    const cardRef1 = useRef(null);
    const cardInView1 = useInView(cardRef1, { once: true, threshold: 0.5 });

    const cardRef2 = useRef(null);
    const cardInView2 = useInView(cardRef2, { once: true, threshold: 0.5 });

    const cardRef3 = useRef(null);
    const cardInView3 = useInView(cardRef3, { once: true, threshold: 0.5 });

    const containerVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                staggerChildren: 0.2,
                delayChildren: 0.1,
            }
        }
    };

    const itemVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: { opacity: 1, y: 0 }
    };
    const borderTopStyle = isMobile ? "none": "1px solid var(--color-gray-04)";
    
    return (
        <GridSystem
            // gridType='spread'
            containerBackgroundColor='#E6E1D6 '
             containerBorderTop={borderTopStyle}
             containerBorderBottom="1px solid var(--color-gray-04)"
        >

            <div className={styles.contentWrapper}>
                <div className={styles.introductionContainer} >
                    <div className={styles.introductionTextWrapper}>
                        <div ref={sectionTitleRef0}>
                            {sectionTitleInView0 && (
                                <AnimatedComponent
                                    type="wipeEffect"
                                    directionStart="left"
                                    delay={0.1}
                                >
                                    <div className={styles.introSubtitleText}>
                                        <PageText type='bodySubtitleBold'>Welcome to the Product Guide</PageText>
                                    </div>
                                </AnimatedComponent>
                            )}

                        </div>

                        <div ref={sectionTitleRef}>
                            {sectionTitleInView && (
                                <AnimatedComponent
                                    type="wipeEffect"
                                    directionStart="left"
                                    delay={0.1}
                                >

                                    <div className={styles.introTitleText}>
                                        <PageText type='bodyTitle'>Find the latest home appliances</PageText>
                                    </div>
                                </AnimatedComponent>
                            )}

                        </div>
                        <div ref={sectionDescriptionRef}>
                            {sectionDescriptionInView && (
                                <AnimatedComponent
                                    type="wipeEffect"
                                    directionStart="left"
                                    delay={0.1}
                                >
                                    <div className={styles.introDescriptionText}>
                                        <PageText type='heroDescription'>
                                            <span className={styles.longIntroDescriptionText}>
                                            Your gateway to mastering LG's top-of-the-line home appliances. The LG Product Guide is your one-stop-shop for everything in home appliances.
                                            </span>
                                            <span className={styles.shortIntroDescriptionText}>
                                            Your gateway to mastering LG's top-of-the-line home appliances. The LG Product Guide is your one-stop-shop for everything in home appliances.
                                            </span>
                                        </PageText>

                                    </div>
                                </AnimatedComponent>
                            )}
                        </div>
                    </div>
                    <div className={styles.imageWrapper}>
                        <div className={styles.headImageGroup}>
                            <div className={styles.headImage1} ref={introImageRef1}>
                                {
                                    introImageInView1 &&
                                    <AnimatedImage
                                        type="wipeEffect" directionStart='left' delay={0}
                                        alt="LG customer on her phone looking at Home Appliance Product List"
                                        src={`/assets/image/backgrounds/builder/lg-customer.webp`}
                                    />
                                }

                            </div>

                            <div className={styles.headImage2} ref={introImageRef2}>
                                {
                                    introImageInView2 &&
                                    <>

                                        <div className={styles.rep1}>
                                            <AnimatedImage
                                                type="wipeEffect" directionStart='left' delay={1}
                                                alt="LG rep on his tablet creating the LG Home Appliance Product List"
                                                src={`/assets/image/backgrounds/builder/lg-rep.webp`}
                                            />
                                        </div>
                                        <div className={styles.rep2}>
                                            <AnimatedImage
                                                type="wipeEffect" directionStart='left' delay={1}
                                                alt="Bottom view of LG rep on his tablet creating the LG Home Appliance Product List"
                                                src={`/assets/image/backgrounds/builder/lg-rep-bottom.webp`}
                                            />
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                    <div className={styles.cardWrapper}>
                        <div ref={cardRef1}>
                            {cardInView1 &&
                                <div className={styles.benefitsCard}>
                                    <div className={styles.card} > {
                                        <BenefitCards
                                            title="Home appliance showcase"
                                            description="Gain real-time access to the latest in home appliances, including specifications, media, installation details, and more."
                                            shortDescription="Gain real-time access to the latest in home appliances."
                                            link="/appliances"
                                        />
                                    }</div>
                                </div>
                            }
                        </div>
                        <div ref={cardRef2}>
                            {cardInView2 &&
                                <div className={styles.benefitsCard}>
                                    <div className={styles.card} > {
                                        <BenefitCards
                                            title="Resource hub"
                                            description="Access up-to-date resources, including feature breakdowns, warranty insights, product comparison charts, and model transition updates—all at your fingertips."
                                           shortDescription="Access up-to-date product information and resources."
                                            link="/model-transitions"
                                        />
                                    }</div>
                                </div>
                            }
                        </div>
                        <div ref={cardRef3}>
                            {cardInView3 &&
                                <div className={styles.benefitsCard}>
                                    <div className={styles.card} > {
                                        <BenefitCards
                                            title="Product list builder"
                                            description="Unlock the power of the exclusive Product List Builder, which allows you to search, select, and organize products effortlessly. Create custom lists that meet your precise needs and generate print-ready lists that highlight essential features, helping your customers make informed choices."
                                           shortDescription="Access the LG Product List Builder, your tool for custom lists."
                                            link="/product-list-builder"
                                        />
                                    }</div>
                                </div>
                            }
                        </div>
                    </div>




                </div>


            </div>

        </GridSystem>

    )
}