import { useDataContext } from "../../../../hooks/data-hook";
import { PortalLeaderBoard } from "../../PortalPageComponent/PortalLeaderBoard/PortalLeaderBoard";
import { PortalLeaderBoardRow } from "../../PortalPageComponent/PortalLeaderBoard/PortalLeaderBoardRow";
import { SkeletonComponent } from "../../../Skeletons/SkeletonComponent";
import { NoDataPortalCard } from "../../PortalPageComponent/PortalCard/NoDataPortalCard";
import { useState, useEffect } from 'react';

export const ResourceLinkClicksByProductLeaderBoard = ({ limit = 10 }) => {
    const { isDataState } = useDataContext(); // Access filtered data from context
    const data = isDataState.resourceDataFilteredByDate;

    const targetEvents = ['RESOURCE_CLICKED'];


    // Define loading and no data states
    const [state, setState] = useState({
        isLoading: true,
        hasData: false,
        showNoDataMessage: false,
    });

    // Reset state when data changes
    useEffect(() => {
        setState({ isLoading: true, hasData: false, showNoDataMessage: false });
    }, [data]);


    // Step 1: Filter 'Click_Resource_Link' events
    const filteredEvents = data ? data.filter(event => targetEvents.includes(event.eventName)) : [];

    // Step 2: Aggregate eventCount by productName
    const productMap = new Map();

    filteredEvents.forEach(item => {
        const productName = item['customEvent:productName'] || 'Unknown Product';
        const eventCount = Number(item.eventCount);

        if (!productMap.has(productName)) {
            productMap.set(productName, { productName, totalLinkClicks: 0 });
        }
        productMap.get(productName).totalLinkClicks += eventCount;
    });

    // Step 3: Sort products by totalLinkClicks and limit the result
    const sortedProducts = Array.from(productMap.values())
        .sort((a, b) => b.totalLinkClicks - a.totalLinkClicks)
        .slice(0, limit);


    // Handle loading and no data logic
    useEffect(() => {
        if (filteredEvents && filteredEvents.length !== 0) {
            setState({ isLoading: false, hasData: true, showNoDataMessage: false });
        } else {
            const timer = setTimeout(() => {
                setState(prevState => ({ ...prevState, isLoading: false, showNoDataMessage: true }));
            }, 2000);

            return () => clearTimeout(timer); // Cleanup timer on unmount
        }
    }, [data]);

    // While loading, show skeleton component
    if (state.isLoading) {
        return <SkeletonComponent height="33rem" count={1} />;
    }

    // If loading is finished and no data is found, show a "No Data" message
    if (!state.hasData && state.showNoDataMessage) {
        return <NoDataPortalCard dataTitle="Most Clicked Resource Links by Product" />;
    }

    return (
        <PortalLeaderBoard title="Most Clicked Resource Links by Product">
            <ul>
                {sortedProducts.map((product, idx) => (
                    <li key={idx}>
                        <PortalLeaderBoardRow
                            rank={idx + 1}
                            dimension={product.productName}
                            metric={product.totalLinkClicks}
                        />
                    </li>
                ))}
            </ul>
        </PortalLeaderBoard>
    );
};
