import { useDataContext } from "../../../../hooks/data-hook";
import { PieChart } from "../../PortalChartComponent/PieChart";
import { useChartConfig } from "../../../../hooks/chart-config-hook";
import { transformWithSchema } from "../../../../utils/data-transformer";
import { PortalCard } from "../../PortalPageComponent/PortalCard/PortalCard";
import { formatChartLabel } from "../../../../utils/helper-functions";
import { SkeletonComponent } from "../../../Skeletons/SkeletonComponent";
import { NoDataPortalCard } from "../../PortalPageComponent/PortalCard/NoDataPortalCard";
import { useState, useEffect } from 'react';

export const SearchTypePieChart = () => {

    const { isDataState } = useDataContext();
    const pieData = isDataState.searchDataFilteredByDate;

    // Define loading and no data states
    const [state, setState] = useState({
        isLoading: true,
        hasData: false,
        showNoDataMessage: false,
    });


    // Reset state when data changes
    useEffect(() => {
        setState({ isLoading: true, hasData: false, showNoDataMessage: false });
    }, [pieData]);

  

    const { config: pieChartOptions } = useChartConfig('PieChart', '');



    const filteredData = pieData ? 
    pieData.filter(item => {
        const searchType = item['customEvent:searchType'];
        return searchType && searchType !== '(not set)' && searchType.trim() !== '';
    }): [];

    // Aggregate the data 
    const aggregatedData = filteredData.reduce((acc, item) => {
        const searchType = item['customEvent:searchType'];
        const searchCount = Number(item.eventCount);

        if (acc[searchType]) {
            acc[searchType] += searchCount;
        } else {
            acc[searchType] = searchCount;
        }
        return acc;
    }, {});
    // Step 3: Find the category with the most users and calculate percentage
    const totalEvents = Object.values(aggregatedData).reduce((acc, curr) => acc + curr, 0);
    const maxCategory = Object.keys(aggregatedData).reduce((max, category) =>
        aggregatedData[category] > aggregatedData[max] ? category : max,
        Object.keys(aggregatedData)[0]
    );
    const maxCategoryEvents = aggregatedData[maxCategory];
    const percentage = ((maxCategoryEvents / totalEvents) * 100).toFixed(2);

    const transformedData = Object.keys(aggregatedData).map(searchEngine => ({
        searchEngine: formatChartLabel(searchEngine),
        searches: aggregatedData[searchEngine],
    }));

    let chartData = null;
    try {
        chartData = transformWithSchema(transformedData, 'searchTypeSnapshot');
    } catch (error) {
        console.error(`Error transforming data: ${error.message}`);
       
    }


      // Handle loading and no data logic
      useEffect(() => {
        // if (pieData && pieData.length > 0) {
            if (filteredData && filteredData.length !== 0) {
            setState({ isLoading: false, hasData: true, showNoDataMessage: false });
        } else {
            const timer = setTimeout(() => {
                setState(prevState => ({ ...prevState, isLoading: false, showNoDataMessage: true }));
            }, 2000);

            return () => clearTimeout(timer); // Cleanup timer on unmount
        }
    }, [pieData]);
         // While loading, show skeleton component
         if (state.isLoading) {
            return <SkeletonComponent height="33rem" count={1} />;
        }
    
        // If loading is finished and no data is found, show a "No Data" message
        if (!state.hasData && state.showNoDataMessage) {
            return <NoDataPortalCard dataTitle="Search by Search Type" />;
        }
    
    

    return (

        <PortalCard
            toolTipText="This chart breaks down user searches based on where they originated. 'Homepage Search' indicates searches conducted from the homepage search bar, while 'Nav Search' represents searches from the navigation bar. Understanding the distribution of search types provides insight into user behavior and their preferred search entry points."

            // toolTipText='Search type refers to the type of search used. Homepage search refers to the search bar on the homepage. Nav search refers to the search bar in the navigation.'
            cardTitle="Search by Search Type"
            cardData={totalEvents}
            cardFooter={`${percentage}% of users searched using the ${formatChartLabel(maxCategory)}.`} // Add footer insight
        >
            <PieChart data={chartData} options={pieChartOptions} />
            {/* {chartData ? (
                <PieChart data={chartData} options={pieChartOptions} />
            ) : (
                <div>No chart data available.</div>
            )} */}
        </PortalCard>
    );
};