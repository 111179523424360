import { motion } from "framer-motion";
import { Button } from "../../Button/Button";
import styles from "./CollarButtons.module.css";
import { GridSystem } from "../../GridSystem/GridSystem";
import { LinkComponent } from "../../Links/LinkComponent";
import { useLinkConfig } from "../../../hooks/use-link-config-hooks";
import { useResponsiveStateHook } from "../../../hooks/responsive-hook";


export const CollarButtons = () => {

    const { categoryLinks } = useLinkConfig();
    const{isMobile} = useResponsiveStateHook();

    const itemVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: { opacity: 1, y: 0 }
    };
    
    const containerVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                staggerChildren: 0.1, // Adjust for timing between children
                delayChildren: 0.1,   // Initial delay before the first child starts animating
                staggerDirection: -1  // Animate from left to right
            }
        }
    };

    const Wrapper = isMobile ? 'div' : motion.div;
    const ItemWrapper = isMobile ? 'div' : motion.div;
    const gridType = isMobile ? "spread": undefined

    return (
        <div className={styles.collar}>
            <GridSystem  
            containerBackgroundColor="#F0ECE4" 
            // gridType='spread' 
            gridType={gridType}
            >
                <Wrapper
                    variants={!isMobile ? containerVariants : undefined}
                    initial={!isMobile ? "hidden" : undefined}
                    animate={!isMobile ? "visible" : undefined}
                    className={styles.collarList}
                >
                    {categoryLinks.map((category, idx) => (
                        <ItemWrapper
                            key={idx}
                            variants={!isMobile ? itemVariants : undefined}
                            className={styles.collarText}
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                            transition={{ type: "spring", stiffness: 300, damping: 15 }}
                        >
                            <LinkComponent href={category.href}>
                                <Button buttonStyleType='secondary'>
                                    {category.text}
                                </Button>
                            </LinkComponent>
                        </ItemWrapper>
                    ))}
                </Wrapper>
            </GridSystem>
        </div>
    );
};