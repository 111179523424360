import { PortalCard } from "../../PortalPageComponent/PortalCard/PortalCard";
import { useChartConfig } from "../../../../hooks/chart-config-hook";

import Skeleton from "react-loading-skeleton";
import { BarChart } from "../../PortalChartComponent/BarChart";
import { useDataContext } from "../../../../hooks/data-hook";

import { SkeletonComponent } from "../../../Skeletons/SkeletonComponent";
import { NoDataPortalCard } from "../../PortalPageComponent/PortalCard/NoDataPortalCard";
import { useState, useEffect } from 'react';

export const UserByDeviceModelBrandingCard = () => {

    const { isDataState } = useDataContext();
    const data = isDataState.technicalDataFilteredByDate;

    // const singleColor = '#3366CC'; // Define a single color for all columns
    // Define loading and no data states
    const [state, setState] = useState({
        isLoading: true,
        hasData: false,
        showNoDataMessage: false,
    });

    // Reset state when data changes
    useEffect(() => {
        setState({ isLoading: true, hasData: false, showNoDataMessage: false });
    }, [data]);

    // Handle loading and no data logic
    useEffect(() => {
        if (data && data.length > 0) {
            setState({ isLoading: false, hasData: true, showNoDataMessage: false });
        } else {
            const timer = setTimeout(() => {
                setState(prevState => ({ ...prevState, isLoading: false, showNoDataMessage: true }));
            }, 2000);

            return () => clearTimeout(timer); // Cleanup timer on unmount
        }
    }, [data]);


    const { config: barChartOptions } = useChartConfig(
        'BarChart',
        '',
        'Users',
        '',
        { minValue: 0 },
        ['#3366CC'],
        false, // Show legend
        true, // Show X-axis labels
        true,
        // 'BarChart',
        // 'Device Branding',
        // 'Users',
        // '',
        // { minValue: 0 },
        // [singleColor],
        // false
    );


    // Aggregate data by operating system
    const dataMap = new Map();

    data && data.forEach((item) => {
        const target = item.mobileDeviceBranding || 'Unknown';
        const count = Number(item.totalUsers);

        if (!dataMap.has(target)) {
            dataMap.set(target, { target, totalUsers: 0 });
        }

        const targetedData = dataMap.get(target);
        targetedData.totalUsers += count;
    });

    // Sort the aggregated data in descending order by totalUsers
    const aggregatedData = Array.from(dataMap.values()).sort((a, b) => b.totalUsers - a.totalUsers);

    // Format the aggregated data for chart rendering
    const chartData = [
        ['Device Model Branding', 'Users'], // Column headers for the chart
        ...aggregatedData.map(item => [item.target, item.totalUsers]) // Map OS and user count
    ];



    // While loading, show skeleton component
    if (state.isLoading) {
        return <SkeletonComponent height="33rem" count={1} />;
    }

    // If loading is finished and no data is found, show a "No Data" message
    if (!state.hasData && state.showNoDataMessage) {
        return <NoDataPortalCard dataTitle="User by Device Brand" />;
    }


    return (
        <PortalCard
            cardTitle="User Distribution by Device Brand"
            toolTipText="This chart displays the number of users categorized by the branding of their devices, providing insights into which device brands are most commonly used to access your application."
        // toolTipText="Manufacturer or branded name (examples: Samsung, HTC, Verizon, T-Mobile)."
        >
            <BarChart data={chartData} options={barChartOptions} />

        </PortalCard>
    );
};