import styles from "./defintions.module.css";
import { FEATURE_DEFINITIONS_DATA } from "../../../data/STATIC_DATA";
import { GroupDataByCategory, capitalizeFirstLetterEachWord} from "../../../utils/helper-functions";
import { PageText } from "../../../components/Text/Text";
import { StickyNavigationBar } from "../../../components/Navigation/StickyNavigationBar";
import { GridSystem } from "../../../components/GridSystem/GridSystem";
import { HeroComponent } from "../../../components/HeroComponent/HeroComponent";
import { useResponsiveStateHook } from "../../../hooks/responsive-hook";

const FeatureDefinitionsPage = () => {

    const { isMobile } = useResponsiveStateHook();
    const groupedData = GroupDataByCategory(FEATURE_DEFINITIONS_DATA);

    const description = isMobile
        ? "This is your go-to destination for a quick and informative overview of LG's cutting-edge technology and innovations."
        : "This is your go-to destination for a quick and informative overview of LG's cutting-edge technology and innovations. You play a pivotal role in guiding customers through our range of products, and we're here to equip you with the knowledge you need to provide exceptional customer experiences. Explore our intuitive and advanced features such as AIDD™, QuadWash® Pro and more."

        const gridType = isMobile ? "spread": undefined
        
    return (
        <div className={styles.featureDefinitionsPageContainer}>
            <HeroComponent
                title="Feature definitions"
                subtitle="Resources"
                description={description}
                imgAlt="lg mom and daughter"
                imgSrc="/assets/image/backgrounds/resources/lg-mom-shape.webp"
            />
            <div className={styles.stickyNavContainer}>
                <StickyNavigationBar data={groupedData} />
            </div>
            {Object.keys(groupedData).map((category, categoryIndex) => (
                <div key={categoryIndex}>
                    <GridSystem 
                    gridType={gridType} 
                    containerBackgroundColor='#E6E1D6'>
                        <div className={styles.contentWrapper}>

                            <div className={styles.featureDefinitionsPageContainer} data-section id={`category-${categoryIndex}`}>
                                <div className={styles.featureDef}>
                                    <div className={styles.categoryTitleWrapper}>
                                        <PageText  type='bodyTitle'>{capitalizeFirstLetterEachWord(category)}</PageText>
                                    </div>
                                    <div>
                                        {groupedData[category].map((item, itemIndex) => (
                                            <div className={styles.definitionWrapper} key={itemIndex}>
                                                <div className={styles.featureTitleWrapper}>
                                                    <PageText type="bodySubtitle">{item.feature}</PageText>
                                                </div>
                                                <div className={styles.featureDescriptionWrapper}>
                                                    <PageText type="bodyDescription">{item.definition}</PageText>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </GridSystem >
                </div >
            ))}
        </div >
    );
};


export default FeatureDefinitionsPage;