import { useDataContext } from "../../../../hooks/data-hook";
import { ColumnChart } from "../../PortalChartComponent/ColumnChart";
import { useChartConfig } from "../../../../hooks/chart-config-hook";
import { PortalCard } from "../../PortalPageComponent/PortalCard/PortalCard";
import { SkeletonComponent } from "../../../Skeletons/SkeletonComponent";
import { NoDataPortalCard } from "../../PortalPageComponent/PortalCard/NoDataPortalCard";
import { useState, useEffect } from 'react';

export const EventsColumnChart = () => {

    const { isDataState } = useDataContext();
    const data = isDataState.isDataFilteredByDate;  // Use the filtered event data

    // Define loading and no data states
    const [state, setState] = useState({
        isLoading: true,
        hasData: false,
        showNoDataMessage: false,
    });

    // Reset state when data changes
    useEffect(() => {
        setState({ isLoading: true, hasData: false, showNoDataMessage: false });
    }, [data]);


    const { config: barChartOptions } = useChartConfig(
        'ColumnChart',
        '',
        'Event Type',
        'Event Count',
        { minValue: 0 },
        ['#3366CC'],
        false, // Show legend
        true, // Show X-axis labels
        true,
    ); // Set up BarChart options



    const targetEvents = [
        'SEARCHED_PRODUCT_SELECTED', 'SEARCHED_PRODUCT_ADDED', 'LIST_PRINTED', 'RESOURCE_CLICKED', 'PRODUCT_ADDED',
    ];

    // Filter the events that match the target events
    const filteredEvents = data && Array.isArray(data)
        ? data.filter(event => targetEvents.includes(event.eventName))
        : [];

    const eventsMap = new Map();

    // Step 1: Aggregate event counts by event name
    filteredEvents.forEach((item) => {
        const key = item.eventName;

        if (!eventsMap.has(key)) {
            eventsMap.set(key, {
                event: item.eventName,
                totalEvents: 0,
            });
        }
        const current = eventsMap.get(key);
        current.totalEvents += Number(item.eventCount); // Aggregate event counts
    });

    const sortedEvents = Array.from(eventsMap.values()).sort((a, b) => b.totalEvents - a.totalEvents);

    // Step 2: Prepare chart data
    const chartData = [
        ['Event Name', 'Event Count'],
        ...sortedEvents.map(item => [item.event, item.totalEvents])
    ];

    // Step 3: Identify the most popular event
    const mostPopularEvent = sortedEvents[0] ? sortedEvents[0].event : 'Unknown Event';
    const mostPopularEventCount = sortedEvents[0] ? sortedEvents[0].totalEvents : 0;
    // Handle loading and no data logic
    useEffect(() => {
        if (filteredEvents && filteredEvents.length > 0) {
            setState({ isLoading: false, hasData: true, showNoDataMessage: false });
        } else {
            const timer = setTimeout(() => {
                setState(prevState => ({ ...prevState, isLoading: false, showNoDataMessage: true }));
            }, 2000);

            return () => clearTimeout(timer); // Cleanup timer on unmount
        }
    }, [data]);

    // While loading, show skeleton component
    if (state.isLoading) {
        return <SkeletonComponent height="33rem" count={1} />;
    }

    // If loading is finished and no data is found, show a "No Data" message
    if (!state.hasData && state.showNoDataMessage) {
        return <NoDataPortalCard dataTitle="Key Events Breakdown" />;
    }

    return (
        mostPopularEvent && mostPopularEventCount && chartData !== null ? (
            <PortalCard
                toolTipText="This chart breaks down the key user interactions within the application. Events include: 'SEARCHED_PRODUCT_SELECTED' (products selected from a search), 'SEARCHED_PRODUCT_ADDED' (products added to the list from a search), 'LIST_PRINTED' (when a user prints their product list), 'RESOURCE_CLICKED' (resource links clicked by users), and 'PRODUCT_ADDED' (products added to a list). Each event provides valuable insights into user behavior and engagement with the application."

                cardTitle="Key Events Breakdown"
                cardFooter={`Most popular event: "${mostPopularEvent}" with ${mostPopularEventCount.toLocaleString()} occurrences.`}
            >  
            <ColumnChart data={chartData} options={barChartOptions} />
                
            </PortalCard>
        ) : (
            <SkeletonComponent height="33rem" count={1} />
        )
    );
};
