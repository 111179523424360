import { useRef } from "react";
import { PageText } from "../../Text/Text";
import styles from "./Resources.module.css";
import { motion, useInView } from "framer-motion";

import { GridSystem } from "../../GridSystem/GridSystem";
import { AnimatedComponent } from "../../../hooks/use-framer-motion";

import { ResourceCards } from "../ResourceCards/ResourceCards";
import { useResponsiveStateHook } from "../../../hooks/responsive-hook";

export const Resource = ({

}) => {

    const sectionTitleRef = useRef(null);
    const sectionTitleInView = useInView(sectionTitleRef, { once: true, threshold: 0.5 });


    const sectionDescriptionRef = useRef(null);
    const sectionDescriptionInView = useInView(sectionDescriptionRef, { once: true, threshold: 0.5 });

    const cardRef1 = useRef(null);
    const cardInView1 = useInView(cardRef1, { once: true, threshold: 0.5 });

    const cardRef2 = useRef(null);
    const cardInView2 = useInView(cardRef2, { once: true, threshold: 0.5 });

    const cardRef3 = useRef(null);
    const cardInView3 = useInView(cardRef3, { once: true, threshold: 0.5 });

    const {isMobile } = useResponsiveStateHook();
    const gridType = isMobile ? "spread": undefined
   
    return (
        <GridSystem
        gridType={gridType}
        containerBackgroundColor="#F6F3EB"
            // gridType='spread'
        >

            <div className={styles.contentWrapper}>
                {/* <div className={styles.sectionHeader}> */}

                    <div className={styles.sectionHeader} ref={sectionTitleRef}>
                        {sectionTitleInView && (
                            <AnimatedComponent
                                type="wipeEffect"
                                directionStart="left"
                                delay={0.1}
                            >
                                <div className={styles.sectionTitle}>
                                    <PageText type='bodyTitle'>Resources</PageText>
                                </div>
                            </AnimatedComponent>
                        )}
                        <div ref={sectionDescriptionRef}>
                            {sectionDescriptionInView && (
                                <AnimatedComponent
                                    type="wipeEffect"
                                    directionStart="left"
                                    delay={0.1}
                                >
                                    <div className={styles.sectionSubtitle}>
                                        <PageText type='heroDescription'>Your central hub for comprehensive product knowledge and support.</PageText>
                                    </div>
                                </AnimatedComponent>
                            )}
                        </div>
                    </div>
                {/* </div> */}

                <motion.div className={styles.cardWrapper}>
                    <div ref={cardRef1}>
                        {cardInView1 &&
                            < ResourceCards
                                title="Feature definitions"
                                description="Explore LG’s cutting-edge technology and innovations."
                                icon='infoDoc'
                            />
                        }
                    </div>
                    <div ref={cardRef2}>
                        {cardInView2 &&
                            < ResourceCards
                                title="Step-up charts"
                                description="Compare products across specifications, key features, dimensions, and more."
                                icon="compare"
                            />
                        }
                    </div>
                    <div ref={cardRef3}>
                        {cardInView3 &&
                            < ResourceCards

                                title='Limited warranties'
                                description="Discover how LG provides customers with confidence in every purchase."
                                icon='warrantyShield'
                            />
                        }
                    </div>
                </motion.div>
            </div>




        </GridSystem>

    )
}